import React, { Fragment } from "react";

export default function PrivacyPolicy() {
  return (
    <Fragment>
      <div className="ml-[150px]">
        <h2 className="mt-12 mb-4 font-bold">Privacy policy</h2>
        <p className="mt-6 mb-4">Our website address is: https://shop.kenpoly.com.</p>
        <p className="mt-6 mb-6">
          The information we collect from users enables us to personalize and
          improve our services, and to allow our users to set up a user account
          and allows for repeat orders to be completed more quickly. This
          document describes the types of information we collect from our users
          and what we do with it.
        </p>
        <h2 className="font-bold mb-4">How do we collect your personal data</h2>
        <p className="mt-8 mb-8">
          When you visit our websites and retail outlets in our company we
          collect personal data about you or if you communicate with us by
          phone, e-mail and social media.
        </p>
        <h2 className="font-bold ml-12 mb-4">The personal data we collect includes:</h2>
        <p className="ml-12 mb-4">
          Personal details such as your name, address, date of birth, email
          address, phone number and other contact information, transaction
          information, such as the product you purchased, its price, your method
          of payment and your payment details.
        </p>
        <p className="ml-12 mb-4">
          The phone numbers that you call/send messages to or the phone numbers
          that you receive calls/messages from.
        </p>
        <p className="ml-12 mb-4">
          The date and time of the calls and messages you send or receive
          through our network, and your location at the time these
          communications take place.
        </p>
        <h2 className="ml-12 mb-4 font-bold mt-8">By using our website, you represent and warrant that:</h2>
        <p className="ml-12 mb-4">
          all registration information you submit will be true, accurate,
          current, and complete;
        </p>
        <p className="ml-12 mb-4">
          you will maintain the accuracy of such information and promptly update
          such registration information as necessary;
        </p>
        <p className="ml-12 mb-4">
          you have the legal capacity and you agree to comply with these Terms
          of Use;
        </p>
        <p className="ml-12 mb-4">
          you are not under the age of 18, or if under the age of 18, you have
          received parental permission to use the Site;
        </p>
        <p className="ml-12 mb-4">
          you will not use the Site for any illegal or unauthorized purpose;
        </p>
        <p className="ml-12 mb-4">
          your use of the Site will not violate any applicable law or
          regulation.
        </p>
        <p className=" mt-12 mb-4">
          If you provide any information that is untrue, inaccurate, not
          current, or incomplete, we have the right to suspend or terminate your
          account and refuse any and all current or future use of the Site (or
          any portion thereof).
        </p>
        <h2 className="font-bold mt-12 mb-6">How we use information we collect</h2>
        <p className=" mb-4 mt-4">
          We use the information we collect to provide, maintain, protect and
          improve our service.
        </p>
        <p className=" mb-4 mt-4">
          When you contact us, we may keep a record of your communication to
          help solve any issues you might be facing.
        </p>
        <p className=" mb-4 mt-4">
          We may use your email address to inform you about our services, such
          as letting you know about upcoming promotions, offers or new products.
        </p>
        <p className=" mb-4 mt-4">
          We use information collected from cookies and other technologies to
          improve your user experience and the overall quality of our services.
        </p>
        <p className=" mb-4 mt-4">
          We will ask for your consent before using information for a purpose
          other than those that are set out in this Privacy Policy.
        </p >
        <p className=" mb-4 mt-4">We will use information as required by law.</p>
      </div>
    </Fragment>
  );
}
