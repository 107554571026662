import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiFillDashboard } from "react-icons/ai";
import { BiSolidCartDownload } from "react-icons/bi";
import { IoIosHome } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import { IoMdLogOut } from "react-icons/io";
import { IoEye } from "react-icons/io5";
import Swal from "sweetalert2";

export default function Account() {
  const [countriesArr, setCountriesArr] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const navigate = useNavigate();

  const handleLogout = () => {
    Swal.fire({
      icon: "info",
      title: "Logging Out",
      text: "Are you sure you want to log out?",
      showCancelButton: true,
      confirmButtonText: "Yes, Log Out",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // Clear userData from localStorage
        localStorage.removeItem("userData");
        // Redirect to the login page
        navigate("/login");
        window.location.reload();
        // Show logout success alert
        Swal.fire({
          icon: "success",
          title: "Logged Out",
          text: "You have been logged out successfully.",
        });
      }
    });
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      // Update state with user details
      setFirstName(userData.firstName);
      setLastName(userData.lastName);
      // Similarly, update other user details
    }
  }, []);

  const handleSaveChanges = () => {
    const updatedUserData = {
      firstName: firstName,
      lastName: lastName,
      // Include other updated user details here
    };
    localStorage.setItem("userData", JSON.stringify(updatedUserData));
    // Optionally, you can show a success message or perform any other action
  };

  return (
    <Fragment>
      <div className="ml-[150px]">
        <div className="flex w-[80vw] gap-4">
          <div className="w-[60vw] mt-[80px]  p-4">
            <label>
              First Name <span className="text-red-500">*</span>{" "}
            </label>
            <br />
            <div className="flex ">
              <input
                className="bg-slate-200"
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                // placeholder="First Name"
              />
            </div>

            <div className="ml-[300px] -mt-[66px]">
              <label>
                Last Name <span className="text-red-500">*</span>
              </label>
              <br />
              <div className="flex ">
                <input
                  className="bg-slate-200"
                  type="text"
                  placeholder="Last Name"
                />
              </div>
            </div>

            <div className="w-[800px] mt-12">
              <label>
                Display Name <span className="text-red-500">*</span>
              </label>
              <br />
              <div className="flex ">
                <input
                  className="bg-slate-200 w-[700px]"
                  type="text"
                  placeholder=" Display Name"
                />
              </div>
              <p>
                This will be how your name will be displayed in the account
                section and in reviews
              </p>
            </div>

            <div className="w-[800px] mt-12">
              <label>
                Email address <span className="text-red-500">*</span>
              </label>
              <br />
              <div className="flex ">
                <input
                  className="bg-slate-200 w-[700px]"
                  type="email"
                  placeholder="Email address"
                />
              </div>
            </div>

            <div className="w-[800px] mt-12 ml-[50px]">
              <label>Password Change</label>
              <br />

              <div className="mt-6">
                <label className="mt-6">
                  Current password (leave blank to leave unchanged)
                  <span className="text-red-500">*</span>
                </label>
                <br />
                <div className="flex ">
                  <input className="bg-slate-200 w-[600px]" type="text" />
                  <IoEye className="-ml-[30px] mt-3" />
                </div>
              </div>

              <div className="mt-6">
                <label className="mt-6">
                  New password (leave blank to leave unchanged)
                  <span className="text-red-500">*</span>
                </label>
                <br />
                <div className="flex ">
                  <input className="bg-slate-200 w-[600px]" type="text" />
                  <IoEye className="-ml-[30px] mt-3" />
                </div>
              </div>

              <div className="mt-6">
                <label className="mt-6">
                  Confirm new password
                  <span className="text-red-500">*</span>
                </label>
                <br />
                <div className="flex ">
                  <input className="bg-slate-200 w-[600px]" type="text" />
                  <IoEye className="-ml-[30px] mt-3" />
                </div>
              </div>
            </div>

            <div className="mt-6">
              <button
                onClick={handleSaveChanges}
                className="bg-red-600 p-2 rounded text-white"
              >
                SAVE CHANGES
              </button>
            </div>
          </div>
          <div className="w-[20vw] border text-red-500">
            <hr />
            <Link to="/dashboard">
              {" "}
              <div className="flex ml-4 mt-6 p-2">
                Dashboard <AiFillDashboard className="ml-[90px] w-[50px]" />
              </div>
            </Link>
            <hr />
            <Link to="/orders">
              {" "}
              <div className="flex ml-4 mt-6 p-2">
                Orders <BiSolidCartDownload className="ml-[120px] w-[50px]" />
              </div>
            </Link>
            <hr />
            <Link to="/address">
              {" "}
              <div className="flex ml-4 mt-6 p-2">
                Addresses <IoIosHome className="ml-[100px] w-[50px]" />
              </div>
            </Link>
            <hr />
            <Link to="/account">
              {" "}
              <div className="flex ml-4 mt-6 p-2">
                Account Details <CgProfile className="ml-[60px] w-[50px]" />
              </div>
            </Link>
            <hr />
            <div className="flex ml-4 mt-6 p-2" onClick={handleLogout}>
              <Link>Log Out</Link>{" "}
              <IoMdLogOut className="ml-[120px] w-[50px]" />
            </div>
            <hr />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
