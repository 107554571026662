import React, { Fragment, useEffect, useState } from "react";
import { format } from 'date-fns';
import axios from "axios";


export default function OrderSuccessPage() {
  const [orderDetails, setOrderDetails] = useState(null);
  const [subtotal, setSubtotal] = useState(0);
  const [billingAddress, setBillingAddress] = useState('');
  const [orderId, setOrderId] = useState(null);
  const [product, setProduct] = useState([])
  const [date, setDate] = useState(null);
  const [email, setEmail] = useState(null);
  const [total, setTotal] = useState(null);
  const [first_name, setFirst_name] = useState(null);
  const [last_name, setLast_name] = useState(null);
  const [address_1, setAddress_1] = useState(null);
  const [address_2, setAddress_2] = useState(null);
  const [city, setCity] = useState(null);
  const [phone, setPhone] = useState(null);
  const [postcode, setPostcode] = useState(null);
  const [method_title, setMethod_title] = useState(null)
  const [name, setName] = useState('')
  const [quantity, setQuantity] = useState('')
  const [productList, setProductList] = useState([])

 


  useEffect(() => {
    axios
      .get(
        "https://checkndeal.skilldevelopers.in/wp-json/wc/v3/orders?consumer_key=ck_5cb86662b3f148136f41dede89999264419cba87&consumer_secret=cs_52258b1d43f07c649c19698bd77974e99648772b&orderby=date&order=desc&per_page=1"
      )
      .then((res) => {
        // Check if there are any orders
        if (res.data.length > 0) {
          const lastOrder = res.data[0];
          setOrderId(lastOrder.number);
          setName(lastOrder.line_items[0].name)
          setQuantity(lastOrder.line_items[0].quantity)
          setTotal(lastOrder.line_items[0].total)
          setDate(lastOrder.date_created);
          setEmail(lastOrder.billing.email);
          setFirst_name(lastOrder.billing.first_name);
          setLast_name(lastOrder.billing.last_name); // Set last_name here
          setAddress_1(lastOrder.billing.address_1);
          setAddress_2(lastOrder.billing.address_2);
          setCity(lastOrder.billing.city);
          setMethod_title(lastOrder.shipping_lines.method_title)
          setPhone(lastOrder.billing.phone);
          setPostcode(lastOrder.billing.postcode);
          setTotal(lastOrder.shipping_total); // Make sure you have total in your API response
          setOrderDetails(lastOrder); // Set the whole order details
          setSubtotal(lastOrder.total - lastOrder.shipping_total); // Calculate subtotal
          setProductList(lastOrder.line_items)
        }
      })
      .catch((error) => {
        console.error("Error fetching orders:", error);
      });
  }, []);
  
  console.log("method_title",productList);


 

  return (
    <Fragment>
      <div className="ml-[150px] mt-6">
        <div>Thank you. Your order has been received.</div>
        <div className="mt-12 bg-slate-100 p-4 mr-[100px]">

            {product && <Fragment>
              <p className="mt-4 mb-2">ORDER NUMBER:</p>
              <p className="mb-2">{orderId}</p>
              <hr />

              <p className="mt-6">DATE:</p>
              <p className="mt-2 mb-2">{format(new Date(date), 'dd MMMM yyyy')}</p>
              <hr />

              <p className=" mt-4 mb-2">EMAIL:</p>
              <p className="mb-2">{email}</p>
              <hr />

              <p className="mt-4 mb-2">TOTAL:</p>
              <p className="mb-2">{total}</p>
              <hr />
            </Fragment>}




        </div>

        <div className="mt-[50px]">Pay with cash upon delivery.</div>
        <div>Order details</div>

        {orderDetails && (
          <div>
            <table className="w-[1100px] mt-6 -ml-[15px] text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 bg-slate-300 p-2 ">
              <thead className="text-xs text-white uppercase bg-red-700 dark:bg-gray-700 dark:text-gray-400 ">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Product
                  </th>

                  <th scope="col" className="px-6 py-3">
                    Total
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white p-2">
                <td className="p-2">{name}&nbsp;<span className="font-bold">X&nbsp;{quantity}</span></td>
                <td>{total}</td>
              </tbody>
            </table>
            
          </div>
        )}
 {orderDetails && (
        <div className="flex w-[1100px] mt-12 gap-4">
          <div className="bg-red-600 w-[500px] p-2">
            <div className="mt-8 mb-2">Subtotal</div>
            {/* <div className="mt-8 mb-2">Shipping</div> */}
            {/* <div className="mt-8 mb-2">Payment Method</div> */}
            <div className="mt-8 mb-2">Total</div>
          </div>
          <div className="w-[500px] p-2">
            <div className="mt-8 mb-2">{total}</div>
            {/* <div className="mt-8 mb-2">{method_title}</div> */}
            {/* <div></div> */}
            <div className="mt-[40px]">{total}</div>
          </div>
        </div>
 )}

        <div className="flex mt-12">
          <div>
            <p className="text-4xl">Billing Address</p>
            {product &&(
              <>
               <div className="mt-6">{first_name}&nbsp;{last_name} </div>
               <div className="mt-6">{address_1}&nbsp;{address_2}</div>
                <div className="mt-6">{postcode}</div>
                <div className="mt-6">{phone}</div>
                <div className="mt-6">{email} </div>
             
              </>
            )}
          </div>
          <div className="ml-[300px]">
            <h1 className="text-4xl">Shipping address</h1>
            {product &&(
              <>
               <div className="mt-6">{first_name}&nbsp;{last_name} </div>
               <div className="mt-6">{address_1}&nbsp;{address_2}</div>
                <div className="mt-6">{postcode}</div>
                <div className="mt-6">{phone}</div>
                <div className="mt-6">{email} </div>
             
              </>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}