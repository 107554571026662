import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiFillDashboard } from "react-icons/ai";
import { BiSolidCartDownload } from "react-icons/bi";
import { IoIosHome } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import Swal from "sweetalert2";
import { IoMdLogOut } from "react-icons/io";

export default function Profile() {
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      const parsedData = JSON.parse(userData);
      setUser(parsedData);
    }
  }, []);
  const handleLogout = () => {
    Swal.fire({
      icon: 'info',
      title: 'Logging Out',
      text: 'Are you sure you want to log out?',
      showCancelButton: true,
      confirmButtonText: 'Yes, Log Out',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        // Clear userData from localStorage
        localStorage.removeItem("userData");
        // Redirect to the login page
        navigate("/login");
        window.location.reload();
        // Show logout success alert
        Swal.fire({
          icon: 'success',
          title: 'Logged Out',
          text: 'You have been logged out successfully.',
        });
      }
    });
  };

  console.log("Userrrrrrrrrrrrrrrrrrrr",user);
  return (
    <Fragment>
      <div className="ml-[150px]">
        <div className="flex w-[80vw] gap-4">
          <div className="w-[60vw]  p-4">
            <div>
              Hello <b>{user && user.data && user.data.display_name}</b>
              &nbsp;&nbsp;(not <b>
                {user && user.data && user.data.display_name}
              </b> ?{" "}
              <button onClick={handleLogout}>
                {" "}
                <Link>Log Out</Link>
              </button>
              )
            </div>
            <div className="mt-6">
              From your account dashboard you can view your{" "}
              <Link className="text-red-500" to="/orders">
                recent orders
              </Link>
              , manage your{" "}
              <Link className="text-red-500" to="/address">
                shipping and billing addresses
              </Link>
              , and{" "}
              <Link to="/account" className="text-red-500">
                edit your password and account details
              </Link>
              .
            </div>
          </div>
          <div className="w-[20vw] border text-red-500">
            <hr />
            <Link to="/dashboard">
              {" "}
              <div className="flex ml-4 mt-6 p-2">
                Dashboard <AiFillDashboard className="ml-[90px] w-[50px]" />
              </div>
            </Link>
            <hr />
            <Link to="/orders">
              {" "}
              <div className="flex ml-4 mt-6 p-2">
                Orders <BiSolidCartDownload className="ml-[120px] w-[50px]" />
              </div>
            </Link>
            <hr />
            <Link to="/address">
              {" "}
              <div className="flex ml-4 mt-6 p-2">
                Addresses <IoIosHome className="ml-[100px] w-[50px]" />
              </div>
            </Link>
            <hr />
            <Link to="/account">
              {" "}
              <div className="flex ml-4 mt-6 p-2">
                Account Details <CgProfile className="ml-[60px] w-[50px]" />
              </div>
            </Link>
            <hr />
            <div className="flex ml-4 mt-6 p-2" onClick={handleLogout}>
              <Link>Log Out</Link>{" "}
              <IoMdLogOut className="ml-[120px] w-[50px]" />
            </div>
            <hr />
          </div>
        </div>
      </div>
    </Fragment>
  );
}