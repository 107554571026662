import { Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./Component/Header/Header";
import Home from "./Component/Home/Home";
import Footer from "./Component/Footer/Footer";
import ProductDetails from "./Component/Home/ProductDetails";
import SignUp from "./Component/User/SignUp";
import PrivacyPolicy from "./Component/User/PrivacyPolicy";
import Cart from "./Component/Cart/Cart";
import Productcategorycratesbottlecrate from "./Component/Home/ProductCategories/productcategorycratesbottlecrate";
import CrateSub from "./Component/Home/SubCategories/Crate/CrateSub";
import BottleCrate from "./Component/Home/SubCategories/Crate/BottleCrate";
import Crate from "./Component/Home/SubCategories/Crate/Crate";
import Furniture from "./Component/Home/SubCategories/Furniture/Furniture";
import Chair from "./Component/Home/SubCategories/Furniture/Chair";
import Dwayer from "./Component/Home/SubCategories/Furniture/Dwayer";
import ShoeRacks from "./Component/Home/SubCategories/Furniture/ShoeRacks";
import Stool from "./Component/Home/SubCategories/Furniture/Stool";
import Table from "./Component/Home/SubCategories/Furniture/Table";
import Trolley from "./Component/Home/SubCategories/Furniture/Trolley";
import Household from "./Component/Home/SubCategories/Household/Household";
import Baskets from "./Component/Home/SubCategories/Household/Baskets";
import BinsDrums from "./Component/Home/SubCategories/Household/BinsDrums";
import BowlsPlates from "./Component/Home/SubCategories/Household/BowlsPlates";
import Buckets from "./Component/Home/SubCategories/Household/Buckets";
import ChopingBoards from "./Component/Home/SubCategories/Household/ChopingBoards";
import Cutlery from "./Component/Home/SubCategories/Household/Cutlery";
import DishRacks from "./Component/Home/SubCategories/Household/DishRacks";
import DustPans from "./Component/Home/SubCategories/Household/DustPans";
import FoodContainers from "./Component/Home/SubCategories/Household/FoodContainers";
import Funnels from "./Component/Home/SubCategories/Household/Funnels";
import Hangers from "./Component/Home/SubCategories/Household/Hangers";
import Jugs from "./Component/Home/SubCategories/Household/Jugs";
import MugsGlasses from "./Component/Home/SubCategories/Household/MugsGlasses";
import SoapDish from "./Component/Home/SubCategories/Household/SoapDish";
import StorageBox from "./Component/Home/SubCategories/Household/StorageBox";
import Trays from "./Component/Home/SubCategories/Household/Trays";
import Basin from "./Component/Home/SubCategories/Household/Basin";
import Industrial from "./Component/Home/SubCategories/Industrial/Industrial";
import Bucket from "./Component/Home/SubCategories/Industrial/Bucket";
import Helmet from "./Component/Home/SubCategories/Industrial/Helmet";
import StorageRack from "./Component/Home/SubCategories/Industrial/StorageRack";
import Kids from "./Component/Home/SubCategories/Kids/Kids";
import BabyChairs from "./Component/Home/SubCategories/Kids/BabyChairs";
import BabyProducts from "./Component/Home/SubCategories/Kids/BabyProducts";
import BottelsLunchBoxes from "./Component/Home/SubCategories/Kids/BottelsLunchBoxes";
import KidsTable from "./Component/Home/SubCategories/Kids/KidsTable";
import Planter from "./Component/Home/SubCategories/Planter";
import Poultry from "./Component/Home/SubCategories/Poultry";
import Exchange from "./Component/Footer/Exchange";
import Consent from "./Component/Footer/Consent";
import ShippingPolicy from "./Component/Footer/ShippingPolicy";
import Profile from "./Component/User/Proile";
import Dashboard from "./Component/User/Dashboard";
import Order from "./Component/User/Order";
import Address from "./Component/User/Address";
import Account from "./Component/User/Account";
import UpdateAddress from "./Component/User/UpdateAddress";
import Checkout from "./Component/Chechout And Payments/Chechout";
import PrivacyPolicys from "./Component/Chechout And Payments/PrivacyPolicy";

import PageNotFound from "./PageNotFound";
import TermsAndCondition from "./Component/Chechout And Payments/TermsAndCondition";
import Header2 from "./Component/Header/Header2";
import { useEffect, useState } from "react";
import PaymentSuccess from "./Component/Chechout And Payments/PaymentSuccess";
import OrderSuccessPage from "./Component/Chechout And Payments/OrderSuccessPage";
import ForgotPassword from "./Component/User/ForgotPassword";
import { useNavigate } from "react-router-dom";
import ViewOrder from "./Component/User/ViewOrder";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user data exists in localStorage
    const userData = localStorage.getItem("userData");
    if (userData) {
      setIsLoggedIn(true);
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <div>
      {isLoggedIn ? <Header2 /> : <Header />}
      <Routes>
        {/* Auth  */}

        {/* Auth End */}
        <Route path="/*" element={<PageNotFound />} />
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<SignUp />} />
        <Route path="/profile" element={<Profile />} />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cart/:id" element={<Cart />} />
        <Route path="/product/:id" element={<ProductDetails />} />
        <Route
          path="/productcategory/crates/bottle/crate"
          element={<Productcategorycratesbottlecrate />}
        />

        {/* Sub Categories Route */}
        <Route path="/product-category/crates/" element={<CrateSub />} />
        {/* <Route
          path="/product-category/crates/bottle-crate/"
          element={<BottleCrate />}
        /> */}
        <Route path="/product-category/crates/crate/" element={<Crate />} />

        {/* Furniture */}
        <Route path="/product-category/furniture/" element={<Furniture />} />
        <Route path="/product-category/furniture/chairs/" element={<Chair />} />
        <Route
          path="/product-category/furniture/drawers/"
          element={<Dwayer />}
        />
        <Route
          path="/product-category/furniture/shoe-racks/"
          element={<ShoeRacks />}
        />
        <Route path="/product-category/furniture/stool/" element={<Stool />} />
        <Route path="/product-category/furniture/table/" element={<Table />} />
        <Route
          path="/product-category/furniture/trolleys/"
          element={<Trolley />}
        />

        {/* HouseHold */}
        <Route path="/product-category/household/" element={<Household />} />
        <Route path="/product-category/household/basins/" element={<Basin />} />
        <Route
          path="/product-category/household/baskets/"
          element={<Baskets />}
        />
        <Route
          path="/product-category/household/bins-drums/"
          element={<BinsDrums />}
        />
        <Route
          path="/product-category/household/bowls/"
          element={<BowlsPlates />}
        />
        <Route
          path="/product-category/household/buckets/"
          element={<Buckets />}
        />
        <Route
          path="/product-category/household/chopping-boards/"
          element={<ChopingBoards />}
        />
        <Route
          path="/product-category/household/cutlery/"
          element={<Cutlery />}
        />
        <Route
          path="/product-category/household/dish-racks/"
          element={<DishRacks />}
        />
        <Route
          path="/product-category/household/dust-pans/"
          element={<DustPans />}
        />
        <Route
          path="/product-category/household/food-containers/"
          element={<FoodContainers />}
        />
        <Route
          path="/product-category/household/funnels/"
          element={<Funnels />}
        />
        <Route
          path="/product-category/household/hangers/"
          element={<Hangers />}
        />
        <Route path="/product-category/household/jugs/" element={<Jugs />} />
        <Route
          path="/product-category/household/mugs/"
          element={<MugsGlasses />}
        />
        <Route
          path="/product-category/household/soap-dish/"
          element={<SoapDish />}
        />
        <Route
          path="/product-category/household/storage-box/"
          element={<StorageBox />}
        />
        <Route path="/product-category/household/trays/" element={<Trays />} />

        {/* Industrial */}
        <Route path="/product-category/industrial/" element={<Industrial />} />
        <Route
          path="/product-category/industrial/bucket/"
          element={<Bucket />}
        />
        <Route
          path="/product-category/industrial/helmet/"
          element={<Helmet />}
        />
        <Route
          path="/product-category/industrial/storage-rack/"
          element={<StorageRack />}
        />

        {/* Kids */}
        <Route path="/product-category/kids/" element={<Kids />} />
        <Route
          path="/product-category/kids/baby-chairs/"
          element={<BabyChairs />}
        />
        <Route
          path="/product-category/kids/baby-products/"
          element={<BabyProducts />}
        />
        <Route
          path="/product-category/kids/bottles-lunch-boxes/"
          element={<BottelsLunchBoxes />}
        />
        <Route
          path="/product-category/kids/table-kids/"
          element={<KidsTable />}
        />

        <Route path="/product-category/planter/" element={<Planter />} />
        <Route path="/product-category/poultry/" element={<Poultry />} />
        <Route path="/exchange-return-policy" element={<Exchange />} />
        <Route path="/consent-notice/" element={<Consent />} />
        <Route
          path="//purchases-payment-and-delivery/"
          element={<ShippingPolicy />}
        />

        {/* Payment Routes */}

        {/* Profile Routes */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/orders" element={<Order />} />
        <Route path="/address" element={<Address />} />
        <Route path="/account" element={<Account />} />

        <Route path="/update-address" element={<UpdateAddress />} />
        <Route path="/terms-of-service/" element={<TermsAndCondition />} />
        <Route path="/privacy-policy/" element={<PrivacyPolicys />} />
        <Route path="/checkout/" element={<Checkout />} />
        <Route path="/paymentverification" element={<PaymentSuccess />} />
        <Route path="/ordersuccess" element={<OrderSuccessPage />} />
        <Route path="/my-account/lost-password/" element={<ForgotPassword />} />
        {/* <Route path="/product/:id" element={<ProductDetails />} /> */}
        <Route path="/order/:id" element={<ViewOrder />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
