import React, { Fragment, useEffect, useState } from "react";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import MailIcon from "@mui/icons-material/Mail";
import { Link } from "react-router-dom";
import logo from "../../Images/logo.png";
import SearchIcon from "@mui/icons-material/Search";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import HomeSharpIcon from "@mui/icons-material/HomeSharp";


export default function Header() {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);

  // const [cartItems, setCartItems] = useState([]);

  const [cartItems, setCartItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const [cartItemss, setCartItemss] = useState([]);

  useEffect(() => {
    // Retrieve cart items from local storage
    const storedCartItems = JSON.parse(localStorage.getItem("cart")) || [];
    setCartItemss(storedCartItems);
  }, []);

  const handleDecrement = (index) => {
    const updatedCartItems = [...cartItems];
    if (updatedCartItems[index].quantity > 1) {
      updatedCartItems[index].quantity--;
      setCartItems(updatedCartItems);
    }
  };

  const handleIncrement = (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems[index].quantity++;
    setCartItems(updatedCartItems);
  };

  const handleRemoveItem = (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems.splice(index, 1); // Remove item at the specified index
    setCartItems(updatedCartItems);

    // Update local storage
    localStorage.setItem("cart", JSON.stringify(updatedCartItems));
  };

  const handleUpdateCart = () => {
    localStorage.setItem("cart", JSON.stringify(cartItems));
  };

  // Calculate subtotal
  const subtotal = cartItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  // Assuming tax is 10% of the subtotal
  const tax = subtotal * 0.1;

  // Calculate total
  const total = subtotal + tax;

  console.log("Cart Item Length Is", cartItemss.price);

  const searchHandle = async (e) => {
    e.preventDefault();
    if (searchQuery.trim() !== "") {
      try {
        const response = await fetch(
          `https://kenpoly.compulynx.co.ke/wp-json/wc/v3/products?consumer_key=ck_5cb86662b3f148136f41dede89999264419cba87&consumer_secret=cs_52258b1d43f07c649c19698bd77974e99648772b&search=${encodeURIComponent(
            searchQuery
          )}`
        );
        if (response.ok) {
          const data = await response.json();
          setSearchResults(data);
        } else {
          console.error("Failed to fetch search results");
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    } else {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    // Retrieve cart items from local storage
    const storedCartItems = JSON.parse(localStorage.getItem("cart")) || [];
    setCartItems(storedCartItems);
  }, []);

  return (
    <div>
      <div className="flex flex-wrap justify-between items-center  p-2 md:p-4 ">
        <div className="flex items-center ml-2">
          <div className="flex items-center mr-4">
            <PhoneEnabledIcon className="text-gray-600" />
            <p className="text-gray-600 ml-1">+254 7099 56789</p>
          </div>
          <div className="flex items-center">
            <MailIcon className="text-gray-600" />
            <p className="ml-1 text-gray-600">Online@kenpoly.com</p>
          </div>
        </div>

        <div className="flex items-center mt-4 md:mt-0">
          <Link className="text-gray-800 mr-4" to="/login">
            Login And Signup
          </Link>
        </div>

        <div className="flex mt-2">
          {/* Logo Div Start */}
          <div className="w-[150px] ml-8 ">
            <Link to="/">
              <img src={logo} />
            </Link>
          </div>
          {/* Logo Div End */}

          {/* Search Bar  Start*/}
          <div>
            {/* Your header content */}
            <div>
              {/* Search Bar  */}
              <form onSubmit={searchHandle}>
                <div className="mt-6 p-2 h-[60px] ml-[250px]  flex">
                  <input
                    type="text"
                    className="border p-4 w-[300px] rounded text-sm"
                    placeholder="Search Products..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="text-red-600 border h-[43px] w-[150px] border-red-600"
                  >
                    <SearchIcon className="-ml-[40px] mr-4" />
                    Search
                  </button>
                </div>
              </form>

              {/* Display search results */}
              <div>
                {searchResults.map((product) => (
                  <Link to={"/product/" + product.id}>
                    <div className="border p-4" key={product.id}>
                      {/* <img src={product.images[0].src} /> */}
                      <p>
                        Product Name :{" "}
                        <b className="text-red-600">{product.name}</b>
                      </p>
                      <p>
                        Product Price :{" "}
                        <b className="text-red-500">{product.price}</b>
                      </p>
                      {/* Add more product details as needed */}
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>

          <Link to="/cart/:id">
            <div className="bg-red-600 w-[200px]  text-white	 ml-[50px] rounded h-[45px] mt-8 flex p-3">
              <LocalMallOutlinedIcon className="ml-4" />
              <p className="ml-4 ">
                <span className="text-[#161c2d] ]">Ksh</span>&nbsp;&nbsp;{" "}
                {total.toFixed(2)}
              </p>
            </div>
          </Link>
        </div>
      </div>
      <div className="flex ml-[150px] w-[1000px] ">
        {" "}
        {/* Dropdown Menu Start */}
        {/* Menu Home  Start*/}
        <div className="w-[70px] h-[41px] mt-2 p-2 lex  color-[#484848] font-extralight border -mr-[20px]">
          <div className="flex justify-center  ">
            <div onMouseLeave={() => setOpen(true)} className="relative ">
              <Link to="/">
                <HomeSharpIcon />
              </Link>
            </div>
          </div>
        </div>
        {/* Menu Home End */}
        {/* Menu One  Start*/}

{/* Properties
static	
fixed	
absolute	
relative	
sticky */}
        <div className="relative">
        <div className="w-[150px] p-2 h-[42px] text-sm lex color-[#484848] font-extralight bg-white">
  <div className="flex justify-center">
    <div onMouseLeave={() => setOpen(false)} className="relative">
      <button
        onMouseOver={() => setOpen(true)}
        className="flex items-center p-2 bg-white border"
      >
        <span className="mr-4">
          CRATES &nbsp;&nbsp;
          <ExpandMoreOutlinedIcon />
        </span>
      </button>
      <ul
        className={`absolute right-0 w-30 py-2 mt-2 rounded-lg shadow-xl bg-white z-10 ${
          open ? "block" : "hidden"
        } hover:bg-white`}
      >
        <Link to="/productcategory/crates/bottle/crate">
          <li className="flex w-full items-center px-3 py-2 text-sm">
            BOTTLE CRATE
          </li>
        </Link>
        <Link to="/product-category/crates/crate">
          <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
            CRATE
          </li>
        </Link>
      </ul>
    </div>
  </div>
</div>
        </div>

        {/* Menu One End */}
        {/* Menu 2  Start*/}
        <div className="w-[200px] h-[42px] text-sm	 -ml-[50px] p-2 color-[#484848] font-extralight">
          <div className="flex justify-center  ">
            <div onMouseLeave={() => setOpen2(false)} className="relative ">
              <button
                onMouseOver={() => setOpen2(true)}
                className="flex items-center  p-2 bg-white border "
              >
                <span className="mr-4">
                  FURNITURE &nbsp;&nbsp;
                  <ExpandMoreOutlinedIcon />
                </span>
              </button>
              <ul
                className={`absolute right-0 w-30 py-2 mt-2 rounded-lg shadow-xl bg-white z-10 ${
                  open2 ? "block" : "hidden"
                }`}
              >
                <Link to="/product-category/furniture/chairs/">
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    CHAIR
                  </li>
                </Link>
                <Link to="/product-category/furniture/drawers/">
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    DRAWYER
                  </li>
                </Link>
                <Link to="/product-category/furniture/stool/">
                  {" "}
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    STOOL
                  </li>
                </Link>
                <Link to="/product-category/furniture/shoe-racks/">
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    SHOE RACKS
                  </li>
                </Link>

                <Link to="/product-category/furniture/table/">
                  {" "}
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    TABLE
                  </li>
                </Link>
                <Link to="/product-category/furniture/trolleys/">
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    TROLLEYS
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
        {/* Menu 2 End */}
        {/* Menu 3  Start*/}
        <div className="relative">
        <div className="w-[200px] h-[42px] text-sm	 -ml-[63px] p-2 color-[#484848] font-extralight bg-white">
          <div className="flex justify-center  ">
            <div onMouseLeave={() => setOpen3(false)} className="relative ">
              <button
                onMouseOver={() => setOpen3(true)}
                className="flex items-center  p-2 bg-white border "
              >
                <span className="mr-4">
                  HOUSEHOLD &nbsp;&nbsp;
                  <ExpandMoreOutlinedIcon />
                </span>
              </button>
              <ul
                className={`absolute right-0 w-30 py-2 mt-2 rounded-lg shadow-xl  bg-white  z-10 ${
                  open3 ? "block" : "hidden"
                }`}
              >
                <Link to="/product-category/household/basins/">
                  {" "}
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    BASINS
                  </li>
                </Link>
                <Link to="/product-category/household/baskets/">
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    BASKETS
                  </li>
                </Link>
                <Link to="/product-category/household/bins-drums/">
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    BINS & DRUMS
                  </li>
                </Link>
                <Link to="/product-category/household/bowls/">
                  {" "}
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    BOWLS & PLATES
                  </li>
                </Link>
                <Link to="/product-category/household/buckets/">
                  {" "}
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    BUCKETS
                  </li>
                </Link>
                <Link to="/product-category/household/chopping-boards/">
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    CHOPPING BOARDS
                  </li>
                </Link>
                <Link to="/product-category/household/cutlery/">
                  {" "}
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    CUTLERY
                  </li>
                </Link>
                <Link to="/product-category/household/dish-racks/">
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    DISH RACKS
                  </li>
                </Link>
                <Link to="/product-category/household/dust-pans/">
                  {" "}
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    DUST PANS
                  </li>
                </Link>
                <Link to="/product-category/household/food-containers/">
                  {" "}
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    FOOD CONTAINERS
                  </li>
                </Link>
                <Link to="/product-category/household/funnels/">
                  {" "}
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    FUNNELS
                  </li>
                </Link>
                <Link to="/product-category/household/hangers/">
                  {" "}
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    HANGERS
                  </li>
                </Link>
                <Link to="/product-category/household/jugs/">
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    JUGS
                  </li>
                </Link>
                <Link to="/product-category/household/mugs/">
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    MUGS & GLASSS
                  </li>
                </Link>
                <Link to="/product-category/household/soap-dish/">
                  {" "}
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    SOAP DISH
                  </li>
                </Link>
                <Link to="/product-category/household/storage-box/">
                  {" "}
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    STORAGE BOX
                  </li>
                </Link>
                <Link to="/product-category/household/trays/">
                  {" "}
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    TRAYS
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
        </div>
        {/* Menu 3 End */}
        {/* Menu 4  Start*/}
        <div className="relative">
        <div className="w-[150px] lex -ml-[37px] mt-2 color-[#484848] text-sm	 font-extralight bg-white">
          <div className="flex justify-center  ">
            <div onMouseLeave={() => setOpen4(false)} className="relative ">
              <button
                onMouseOver={() => setOpen4(true)}
                className="flex items-center  p-2 bg-white border "
              >
                <span className="mr-4">
                  INDUSTRIAL &nbsp;&nbsp;
                  <ExpandMoreOutlinedIcon />
                </span>
              </button>
              <ul
                className={`absolute right-0 w-30 py-2 mt-2 rounded-lg shadow-xl bg-white z-10 ${
                  open4 ? "block" : "hidden"
                }`}
              >
                <Link to="/product-category/industrial/helmet/">
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    HELMET
                  </li>
                </Link>
                <Link to="/product-category/industrial/storage-rack/">
                  {" "}
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    STORAGE RACK
                  </li>
                </Link>
                <Link to="/product-category/industrial/bucket/">
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    BUCKET
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
        </div>
        {/* Menu 4 End */}
        {/* Menu 5  Start*/}
        <div className="w-[150px] lex -ml-[35px] mt-2 color-[#484848] text-sm	 font-extralight">
          <div className="flex justify-center  ">
            <div onMouseLeave={() => setOpen5(false)} className="relative ">
              <button
                onMouseOver={() => setOpen5(true)}
                className="flex items-center  p-2 bg-white border "
              >
                <span className="mr-4">
                  KIDS &nbsp;&nbsp;
                  <ExpandMoreOutlinedIcon />
                </span>
              </button>
              <ul
                className={`absolute right-0 w-30 py-2 mt-2 rounded-lg shadow-xl bg-white z-10 ${
                  open5 ? "block" : "hidden"
                }`}
              >
                <Link to="/product-category/kids/baby-chairs/">
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    BABY CHAIRS
                  </li>
                </Link>
                <Link to="/product-category/kids/baby-products/">
                  {" "}
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    BABY PRODUCTS
                  </li>
                </Link>
                <Link to="/product-category/kids/bottles-lunch-boxes/">
                  {" "}
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    BOTTELS & LUNCH BOXES
                  </li>
                </Link>
                <Link to="/product-category/kids/table-kids/">
                  {" "}
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    KIDS TABLE
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
        {/* Menu 5 End */}
        {/* Menu 6  Start*/}
        <div className="w-[150px] text-sm	 lex -ml-[53px] h-[60px] mt-2   color-[#484848] font-extralight">
          <div className="flex justify-center  ">
            <div onMouseLeave={() => setOpen6(false)} className="relative ">
              <Link to="/product-category/planter/">
                <button
                  onMouseOver={() => setOpen6(true)}
                  className="flex items-center  p-2 bg-white border "
                >
                  <span className="mr-4">PLANTER &nbsp;&nbsp;</span>
                </button>
              </Link>
            </div>
          </div>
        </div>
        {/* Menu 6 End */}
        {/* Menu 7  Start*/}
        <div className="w-[150px]  text-sm	 lex -ml-[55px] mt-2  color-[#484848] font-extralight">
          <div className="flex justify-center  ">
            <div onMouseLeave={() => setOpen7(false)} className="relative ">
              <Link to="/product-category/poultry/">
                <button
                  onMouseOver={() => setOpen7(true)}
                  className="flex items-center  p-2 bg-white border "
                >
                  <span className="mr-4">POULTRY &nbsp;&nbsp;</span>
                </button>
              </Link>
            </div>
          </div>
        </div>
        {/* Menu 7 End */}
        {/* Dropdown Menu End */}
      </div>
    </div>
  );
}
