import React, { Fragment, useEffect, useState } from "react";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactStars from "react-rating-stars-component";

export default function Funnels() {
  const [price, setPrice] = useState([0, 25000]);
  const [product, setProduct] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://checkndeal.skilldevelopers.in/wp-json/wc/v3/products?category=110&consumer_key=ck_5cb86662b3f148136f41dede89999264419cba87&consumer_secret=cs_52258b1d43f07c649c19698bd77974e99648772b&per_page=45"
      )
      .then((res) => {
        setProduct(res.data);
      });
  }, []);

  const priceHandler = (event, newPrice) => {
    setPrice(newPrice);
  };

  return (
    <Fragment>
      <div className="w-[95vw] ml-5">
        <div className="w-[25vw] p-2 border">
          <div className="bg-slate-200 p-2">
            <h2>
              FILTER BY PRICE
              <div className="text-sm mt-4">
                <Typography className="text-sm mt-4">Price</Typography>
                <Slider
                  className="p-2"
                  value={price}
                  onChange={priceHandler}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  min={0}
                  max={25000}
                />
              </div>
            </h2>
          </div>
          <hr />
        </div>

        {/*  Side Div */}
        <div className="w-[70vw] ml-[350px] -mt-[145px] ">
          <div className="ml-8 mt-4 flex gap-4 text-sm">
            <select className="w-[200px] text-sm">
              <option>Sort by popularity</option>
              <option>Default sorting</option>
              <option>Sort by average rating</option>
              <option>Sort by latest</option>
              <option>Sort by price : low to high</option>
              <option>Sort by price : high to low</option>
            </select>
            <p className="mt-2 text-sm">Showing all {product.length} results</p>
          </div>

          <div className="mt-4 ml-4 mr-4">
            <h1 className="ml-4 text-[#212529] text-xl">Best Seller</h1>

            <div className="grid grid-cols-4 gap-2 ml-4 mt-4 mr-12">
              {product
                .filter((item) => {
                  const itemPrice = parseFloat(item.price);
                  return itemPrice >= price[0] && itemPrice <= price[1];
                })
                .map((ob, index) => (
                  <Link to={"/product/" + ob.id} key={index}>
                    <div className="border p-2 col-span-1">
                      <div>
                        <img className="p-2" src={ob.images[0].src} alt="" />
                      </div>
                      <p className="text-[#000] text-center text-[10px] mt-2">{ob.name}</p>
                      <ReactStars
                        count={ob.average_rating}
                        size={24}
                        isHalf={true}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#D51B21"
                        color="#D51B21"
                      />
                      <p className="text-[#000] text-center text-xs mt-6">
                        KSh <span className="text-red-500">{ob.price} </span>{" "}
                      </p>
                      <button className="bg-red-700 p-2 text-[10px] text-white mt-2 h-[30px] ml-[50px]">
                        SELECT COLOUR
                      </button>
                    </div>
                  </Link>
                ))}
            </div>
          </div>

          <div className="ml-8 mt-6 flex gap-4 text-sm">
            <select className="w-[200px] text-sm">
              <option>Sort by popularity</option>
              <option>Default sorting</option>
              <option>Sort by average rating</option>
              <option>Sort by latest</option>
              <option>Sort by price : low to high</option>
              <option>Sort by price : high to low</option>
            </select>
            <p className="mt-2 text-sm">Showing all {product.length} results</p>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
