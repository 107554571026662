import React, { Fragment, useEffect, useState } from "react";
import { TiDelete } from "react-icons/ti";
import { Link } from "react-router-dom";

export default function Cart() {
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    // Retrieve cart items from local storage
    const storedCartItems = JSON.parse(localStorage.getItem("cart")) || [];
    setCartItems(storedCartItems);
  }, []);

  const handleDecrement = (index) => {
    const updatedCartItems = [...cartItems];
    if (updatedCartItems[index].quantity > 1) {
      updatedCartItems[index].quantity--;
      setCartItems(updatedCartItems);
    }
  };

  const handleIncrement = (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems[index].quantity++;
    setCartItems(updatedCartItems);
  };

  const handleRemoveItem = (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems.splice(index, 1); // Remove item at the specified index
    setCartItems(updatedCartItems);
    window.location.reload();
    // Update local storage
    localStorage.setItem("cart", JSON.stringify(updatedCartItems));
  };

  const handleUpdateCart = () => {
    localStorage.setItem("cart", JSON.stringify(cartItems));
    window.location.reload()
  };

  // Calculate subtotal
  const subtotal = cartItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  // Assuming tax is 10% of the subtotal
  // const tax = subtotal * 0.1;

  // Calculate total
  const total = subtotal;

  return (
    <Fragment>
      <div>
        <div>
          <div className="relative overflow-x-auto">
            <table className="w-[1150px] text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ml-[50px] mr-[100px]">
            {/* {cartItems.length === 0 ?  : } */}
              <thead className="text-xs text-white uppercase bg-red-700 dark:bg-gray-700 dark:text-gray-400 ">
                <tr>
                  <th scope="col" className="px-6 py-3"></th>
                  <th scope="col" className="px-6 py-3"></th>
                  <th scope="col" className="px-6 py-3">
                    Product
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Price
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Quantity
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Subtotal
                  </th>
                </tr>
              </thead>
              {cartItems.length === 0 ? (
                <tbody>
                  <div>
                    <div className="border  border-cyan-500	p-4 mt-6 ml-6 w-[1000px]">
                      Your cart is currently empty.
                    </div>
                    <div>
                      <Link to="/">
                        <button className="w-[200px] bg-red-700 p-2 mb-6 rounded text-white mt-6 ml-[800px]">
                          Return To Shop
                        </button>
                      </Link>
                    </div>
                  </div>
                </tbody>
              ) : (
                <tbody>
                  {cartItems.map((item, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <td>
                        <button
                          className="ml-6"
                          onClick={() => handleRemoveItem(index)}
                        >
                          <TiDelete />
                        </button>
                      </td>
                      <td></td>
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        <Link to={"/product/" + item.id}>
                          {" "}
                         <div className="flex">
                         <img className="w-[50px] -mt-[10px]" src={item.image} /> &nbsp;&nbsp;{item.name}&nbsp;-&nbsp;{item.color}
                         </div>
                        </Link>
                      </th>
                      <td className="px-6 py-4">KSh {item.price}</td>
                      <td className="px-2 py-1">
                        <button
                          className="bg-blue-500 p-2"
                          onClick={() => handleDecrement(index)}
                        >
                          -
                        </button>
                        <b className="ml-2 mr-2">{item.quantity}</b>
                        <button
                          className="bg-blue-500 p-2"
                          onClick={() => handleIncrement(index)}
                        >
                          +
                        </button>
                      </td>
                      <td className="px-6 py-4">
                        {item.price * item.quantity}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
            <div className="flex mt-10 ml-[50px] mb-4">
              <div>
                <input type="text" />
                <button className="ml-2 bg-red-700 p-2 rounded text-white">
                  Apply Coupon
                </button>
              </div>
              <div className="ml-[630px]">
                <button
                  className="ml-2 bg-red-700 p-2 rounded text-white"
                  onClick={handleUpdateCart}
                >
                  Update Cart
                </button>
              </div>
            </div>

            <div className="w-[500px] bg-slate-100 ml-[690px]">
              <div className="  mt-12">
                <h1 className="text-red-600 mt-[30px] ml-[40px] text-xl 	">
                  CART TOTALS
                </h1>
              </div>

              <div className="border p-4 ">
                <div className="flex">
                  <b className="text-sky-800	">Subtotal</b>
                  <p className="ml-[100px]">KSh {subtotal.toFixed(2)}</p>
                </div>
                {/* <div className="flex mt-4">
                  <b className="text-sky-800	">Tax</b>
                  <p className="ml-[130px]">KSh {tax.toFixed(2)}</p>
                </div> */}
                <div className="flex mt-4">
                  <b className="text-sky-800	">Total</b>
                  <p className="ml-[130px]">
                    <b>KSh {total.toFixed(2)}</b>
                  </p>
                  <br />
                </div>
              </div>
              <div className="ml-[630px]">
                <Link to="/checkout/">
                  <button className="-ml-[630px] w-[500px] bg-red-700 p-4 mb-6 rounded text-white ">
                    Proceed To Checkout
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </Fragment>
  );
}
