import React, { Fragment } from "react";

export default function Consent() {
  return (
    <Fragment>
      <div className="ml-[150px] mt-6 mr-[150px]">
        <div className="bg-slate-300 text-center p-6 font-bold	">
          CONSENT NOTICE
        </div>
        <p className="mt-6 mb-6 font-light">
          This Consent Notice outlines the purposes for which{" "}
          <span className="font-normal">Kenpoly Manufacturers Limited</span>{" "}
          will collect, receive, use, disseminate, and process personal and
          sensitive data of data subjects, i.e., staff, customers/clients,
          vendors/suppliers, and other related third parties, in accordance with
          the Data Protection Act, 2019.
        </p>
        <div className="bg-slate-300  p-6 font-bold	">PERSONAL INFORMATION:</div>
        <p className="mt-6 mb-6 font-light">
          1.As used in this Notice:{" "}
          <span className="font-normal">“consent” </span>means any manifestation
          of expss, unequivocal, free, specific and informed indication of the
          data subject’s wishes by a statement or by a clear affirmative action,
          signifying agreement to the processing of personal data relating to
          the data subject.{" "}
          <span className="font-normal"> “personal information” </span>will be
          used to refer to both personal data and sensitive data as defined in
          the Data Protection Act, 2019.{" "}
          <span className="font-normal">“personal data”</span> means information
          identifiable to an individual owing to their relationship with the
          company, which includes: name, gender, contact details, postal
          address, email address, birth date, nationality, ID/Passport
          information, KRA PIN, NSSF number, NHIF number, bank account
          information. <span className="font-normal">“sensitive data”</span>
          means information revealing the data subject’s health status, ethnic
          social origin, conscience, belief, genetic data, biometric data,
          property details, marital status, family details including names of
          the person’s children, parents, spouse or spouses, sex or the sexual
          orientation of the data subject.
          <span className="font-normal"> “you”</span> shall be used to mean the
          data subject, i.e., staff, customers/clients, vendors/suppliers.
        </p>
        <p className="font-light">
          2.Other terms used in this Notice shall have the meanings given to
          them as defined in the Data Protection Act, 2019.
        </p>

        <div className="bg-slate-300  p-6 font-bold	mt-6">
          COLLECTION AND PROCESSING OF PERSONAL INFORMATION:
        </div>
        <p className="font-light mt-6">
          3.We may collect, use, and process personal information that is:
        </p>
        <p className="mt-6">
          a. provided to us directly by you through one-on-one interaction,
          physical forms, online platforms, documents
          <br />
          supplied by you.
        </p>
        <p>
          c. collected through interaction with our duly authorized employees
          and/or contracted third parties.
        </p>
        <p>d. part of public records or that has been made public by you.</p>
        <p className="mt-6">
          4.We will ensure that collection of any personal information is done
          in a transparent manner and used for
          <br /> legitimate purposes known to you and that are compatible with
          the services sought by you.
        </p>
        <p className="mt-6">
          5.We shall seek the consent of the parent and/or guardian when
          collecting personal information relating to a child.
        </p>
        <p className="mt-6">
          6.We shall inform you of third parties to whom we will disclose your
          personal information, and safeguards that are
          <br /> put in place to ensure its protection.
        </p>
        <p className="mt-6">
          7.Personal information collected directly or indirectly will be used
          for any or all of the following purposes:
        </p>
        <p className="mt-4">
          a. administering and/or managing relationship between you and the
          company.
        </p>
        <p>b. performance of a contract to which you are a party to.</p>
        <p>
          c. carrying out tasks in relation to provision of the goods and/or
          services requested by you.
        </p>
        <p>
          d. conveying direct or indirect marketing/promotional information
          about our goods and/or services.
        </p>
        <p>e. handling inquiries, complaints and requests from you.</p>
        <p>
          f. ensuring compliance with any laws and regulations to which the
          company is subject to.
        </p>
        <p>
          g. transmitting to third parties including our third-party service
          providers and agents, and relevant governmental <br />
          and/or regulatory authorities.
        </p>
        <p>h. risk assessment, statistical and planning purposes.</p>
        <p>i. any other purposes to which you have provided.</p>
        <p className="mt-6">
          8.We shall seek your consent before collecting and processing personal
          information for any additional purpose <br />
          that is not part of the original purposes that had been notified to
          you, except where authorised by law.
        </p>
        <p className="mt-6">
          9.The personal information collected will be stored in storage
          locations and servers that are located in Kenya.
        </p>
        <p className="mt-6">
          10. We may disclose your personal information to third party service
          providers or agents and other organisations,
          <br /> situated in Kenya, that we have engaged to perform any of the
          purposes listed above.
        </p>
        <div className="bg-slate-300  p-6 font-bold	mt-6">
          PROTECTION OF PERSONAL INFORMATION:
        </div>
        <p className="mt-6">
          14. You may exercise your right of revocation or withdrawal of consent
          to the collection, processing and <br />
          dissemination of your personal information. Such consent may be
          withdrawn by submitting a formal request in
          <br /> writing or via email to our Data Protection Officer using the
          contact details provided below.
        </p>
        <p className="mt-6">
          15. The withdrawal of your consent shall not affect the lawfulness of
          processing based on prior consent
          <br /> before its withdrawal.
        </p>
        <p className="mt-6">
          16. Upon receipt of your written request to withdraw your consent, we
          may require reasonable time for your <br />
          request to be processed and for us to notify you of its consequences.
        </p>

        <div className="bg-slate-300  p-6 font-bold	mt-6">
          ACCESS AND RECTIFICATION OF PERSONAL INFORMATION:
        </div>
        <p className="mt-6">
          17. You may request access to your personal information that is in our
          custody.
        </p>
        <p className="mt-6">
          18. You may request the rectification and/or update to any of your
          personal information that is inaccurate, <br /> out-dated, incomplete,
          or misleading. Such request should be made in writing or via email to
          our Data <br /> Protection Officer using the contact details provided
          below.
        </p>
        <p className="mt-6">
          19. We will respond to your requests as soon as reasonably possible.
          If we are unable to fulfil a data access
          <br /> request, correct and/or update your personal information as
          requested, we shall inform you of our reasons <br /> except where we
          are not required to do so under the Data Protection Act, 2019
        </p>
        <div className="bg-slate-300  p-6 font-bold	mt-6">
          RETENTION OF PERSONAL INFORMATION:
        </div>

        <p className="mt-6">
          20. We may retain your personal information for as long as may be
          reasonably necessary to satisfy the purpose
          <br /> for which it is processed, unless the retention is;
        </p>
        <p>a. required or authorized by law.</p>
        <p>b. reasonably necessary for a lawful purpose.</p>
        <p>c. authorized or consented by you.</p>
        <p>
          d. for historical, statistical, journalistic literature and art or
          research purposes.
        </p>
        <p className="mt-6">
          21. We will cease to retain your personal information and shall
          delete, erase, anonymize or pseudonymize <br />
          it as soon as we (the company) have determined that its intended
          purpose is no longer applicable.
        </p>

        <div className="bg-slate-300  p-6 font-bold	mt-6">
          DATA PROTECTION OFFICER:
        </div>
        <p className="mt-6">
          22. The Data Protection Officer may restrict the processing of
          personal information where its accuracy is contested, .it is no longer
          required for its initial purpose, the processing is unlawful, and/or
          you have objected to its processing.
        </p>
        <p className="mt-6">
          23. Personal information that has been restricted shall only be
          processed with your consent and you shall be duly informed before
          withdrawal of the restriction.
        </p>
        <p className="mt-6">
          24. You may contact the Data Protection Officer to request:
        </p>
        <p className="mt-6">
          a. objection to the processing of personal information.
        </p>
        <p>
          b. rectification of personal information that is inaccurate,
          out-dated, or misleading.
        </p>
        <p>
          c. deletion of personal information that we are no longer authorized
          to retain.
        </p>
        <p className="mt-6">
          25. You may contact our Data Protection Officer if you have any
          inquiries or feedback related to our data privacy and protection
          policies and procedures.
        </p>
        <p className="mt-6">
          26. Our Data Protection Officer’s contact details are: Email:
          dpo@kenpoly.com
        </p>

        <div className="bg-slate-300  p-6 font-bold	mt-6">
          REVIEW AND CHANGES TO THE NOTICE:
        </div>
        <p className="mt-6">
          27. This Notice applies in concurrence with relevant data privacy
          regulations such as the Data Protection Act, 2019, the company’s
          privacy policies and procedures, and/or other terms and conditions
          that exist on our website, mobile applications, and e-commerce
          platform, in relation to the collection and processing of your
          personal information.
        </p>

        <p className="mt-6">
          28. This Notice may be revised and/or updated periodically by
          providing formal notifications to you, including posting on our online
          platforms and official website.
        </p>
        <div className="bg-slate-300  p-6 font-bold	mt-6">
          ACKNOWLEDGEMENT AND CONSENT:
        </div>
        <p className="mt-6">
          By reading this notice and proceeding to transact with us, you agree
          to all the terms stated, and consent to the collection, use and
          processing of your personal information by Kenpoly Manufacturers
          Limited for the aforementioned purposes within this Notice.
        </p>
      </div>
    </Fragment>
  );
}
