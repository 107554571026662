import React, { Fragment } from "react";

export default function PrivacyPolicy() {
  return (
    <Fragment>
      <div className="col-full ml-[30px]">
        <div className="woocommerce"></div>
        <div id="primary" className="content-area">
          <main id="main" className="site-main" role="main">
            <article
              id="post-3"
              className="post-3 page type-page status-publish hentry"
            >
              <header className="entry-header mt-4 mb-4 font-semibold">
                <h1 className="entry-title font-medium">Privacy Policy</h1>{" "}
              </header>
              <div className="entry-content">
                <p className="mt-4 font-light	mb-6">
                  Our website address is: https://shop.kenpoly.com.
                </p>

                <p className="mt-4 font-light	mb-6">
                  The information we collect from users enables us to
                  personalize and improve our services, and to allow our users
                  to set up a user account and allows for repeat orders to be
                  completed more quickly. This document describes the types of
                  information we collect from our users and what we do with it.
                </p>
                <p></p>
                <p className="mt-4 mb-4 font-medium">
                  <strong className="font-medium">
                    How do we collect your personal data
                  </strong>
                </p>

                <p className="mt-4 font-light	mb-6">
                  When you visit our websites and retail outlets in our company
                  we collect personal data about you or if you communicate with
                  us by phone, e-mail and social media.
                </p>

                <p className="ml-10 mb-6 font-medium">
                  <strong className="font-medium">
                    The personal data we collect includes:
                  </strong>
                </p>
                <ul className="ml-10 mb-6">
                  <li>
                    <p className=" mb-6 text-sm font-light">
                      Personal details such as your name, address, date of
                      birth, email address, phone number and other contact
                      information, transaction information, such as the product
                      you purchased, its price, your method of payment and your
                      payment details.
                    </p>
                  </li>

                  <li>
                    <p className=" mb-6 text-sm font-light">
                      The phone numbers that you call/send messages to or the
                      phone numbers that you receive calls/messages from.
                    </p>
                  </li>

                  <li>
                    <p className=" mb-6 text-sm font-light">
                      The date and time of the calls and messages you send or
                      receive through our network, and your location at the time
                      these communications take place.
                    </p>
                  </li>
                </ul>

                <ul className="ml-10 mb-6">
                  <p>
                    <strong className="font-medium">
                      By using our website, you represent and warrant that:
                    </strong>
                  </p>

                  <li>
                    <p className=" mb-6 text-sm font-light mt-4">
                      all registration information you submit will be true,
                      accurate, current, and complete;
                    </p>
                  </li>

                  <li>
                    <p className=" mb-6 text-sm font-light mt-4">
                      you will maintain the accuracy of such information and
                      promptly update such registration information as
                      necessary;
                    </p>
                  </li>

                  <li>
                    <p className=" mb-6 text-sm font-light mt-4">
                      you have the legal capacity and you agree to comply with
                      these Terms of Use;
                    </p>
                  </li>

                  <li>
                    <p className=" mb-6 text-sm font-light mt-4">
                      you are not under the age of 18, or if under the age of
                      18, you have received parental permission to use the Site;
                    </p>
                  </li>

                  <li>
                    <p className=" mb-6 text-sm font-light mt-4">
                      you will not use the Site for any illegal or unauthorized
                      purpose;
                    </p>
                  </li>

                  <li>
                    <p className=" mb-6 text-sm font-light mt-4">
                      your use of the Site will not violate any applicable law
                      or regulation.
                    </p>
                  </li>
                </ul>

                <p className=" mb-6 text-sm font-light mt-4">
                  If you provide any information that is untrue, inaccurate, not
                  current, or incomplete, we have the right to suspend or
                  terminate your account and refuse any and all current or
                  future use of the Site (or any portion thereof).
                </p>

                <p className="mt-4 mb-4">
                  <strong className="font-medium">
                    How we use information we collect
                  </strong>
                </p>

                <p className=" mb-6 text-sm font-light mt-4">
                  We use the information we collect to provide, maintain,
                  protect and improve our service.
                </p>

                <p className=" mb-6 text-sm font-light mt-4">
                  When you contact us, we may keep a record of your
                  communication to help solve any issues you might be facing.
                </p>

                <p className=" mb-6 text-sm font-light mt-4">
                  We may use your email address to inform you about our
                  services, such as letting you know about upcoming promotions,
                  offers or new products.
                </p>

                <p className=" mb-6 text-sm font-light mt-4">
                  We use information collected from cookies and other
                  technologies to improve your user experience and the overall
                  quality of our services.
                </p>

                <p className=" mb-6 text-sm font-light mt-4">
                  We will ask for your consent before using information for a
                  purpose other than those that are set out in this Privacy
                  Policy.
                </p>

                <p className=" mb-6 text-sm font-light mt-4">
                  We will use information as required by law.
                </p>
              </div>
            </article>
          </main>
        </div>
      </div>
    </Fragment>
  );
}
