import React, { Fragment } from "react";

export default function forgotPassword() {


  const forgotPasswordApi = ''
  return (
    <Fragment>
      <div className="ml-[150px] mt-6">
        <div>
          Lost your password? Please enter your username or email address. You
          will receive a link to create a new password via email.
        </div>
        <div className="mt-10">
          <label>Username or email</label>
          <br />
          <input type="email" className="bg-slate-100 w-[500px]" />
        </div>
        <button className="bg-red-600 p-4 mt-6 text-white">Reset Password</button>
      </div>
    </Fragment>
  );
}
