import React, { useState, useEffect } from "react";
import Carousels from "./Carousel";
import OurRange from "./OurRange";

export default function Home() {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const ratingChanged = (newRating) => {
    console.log(newRating);
  };

  const searchHandle = async (e) => {
    e.preventDefault();
    if (searchQuery.trim() !== "") {
      let result = await fetch(
        `https://checkndeal.skilldevelopers.in/wp-json/wc/v3/products?search=${encodeURIComponent(
          searchQuery
        )}`
      );
      result = await result.json();
      setSearchResults(result);
    } else {
      setSearchResults([]);
    }
  };

  return (
    <div>
      {/* Display search results */}
      <div>
        {searchResults?.map((product, index) => (
          <div key={index}>
            {/* Render each product */}
            <p>{product.name}</p>
          </div>
        ))}
      </div>

      <div>
        <div className=" mb-6 h-[450px] ">
          {/* Carousel Here */}
          <Carousels />
        </div>

        <div className="mt-[150px]">
          {/* Our Range Here*/}
          <OurRange />
        </div>
      </div>
    </div>
  );
}
