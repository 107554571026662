import React, { Fragment } from "react";

export default function Exchange() {
  return (
    <Fragment>
      <div className="ml-[200px]">
        <ul className="font-light	">
          <li>
            We are committed to ensure you have a quality product from Kenpoly.
          </li>
          <li className="mt-4">
            <span className="font-semibold	">Returns</span> – In case you are not
            satisfied with your purchase, you can return the product unused and
            in its original packaging within 7 days from the date of delivery,
            for an exchange for another product or a full refund. We will issue
            a full refund on receipt, excluding the original delivery charges.
          </li>
        </ul>

        <p className="font-semibold mt-6">
          Note: No cost will be incurred for returning the product immediately
          with our delivery courier. However, the customer shall pay for the
          return delivery costs if returning the product after the courier
          leaves. Please ensure the product is packed well and returned unused
          with the original receipt / Tax invoice.
        </p>
      </div>
    </Fragment>
  );
}
