import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiFillDashboard } from "react-icons/ai";
import { BiSolidCartDownload } from "react-icons/bi";
import { IoIosHome } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import { IoMdLogOut } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import Swal from "sweetalert2";
import axios from "axios";

export default function Address() {
  const params = useParams();
  const navigate = useNavigate();
  const [checkoutFormData, setCheckoutFormData] = useState(null);
  const [first_name, setFirst_name] = useState(null);
  const [last_name, setLast_name] = useState(null);
  const [address_1, setAddress_1] = useState(null);
  const [address_2, setAddress_2] = useState(null);
  const [postcode, setPostcode] = useState(null);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");

  // Get Address Method From Order API
  useEffect(() => {
    // Get User Data from Local Storage
    const userData = localStorage.getItem("userData");

    const parsedUserData = JSON.parse(userData);
    const id = parsedUserData.ID;
    console.log("IDDDDDDDDDDDDDDD", id);

    axios
      .get(
        `https://checkndeal.skilldevelopers.in/wp-json/wc/v3/customers/${id}/?consumer_key=ck_5cb86662b3f148136f41dede89999264419cba87&consumer_secret=cs_52258b1d43f07c649c19698bd77974e99648772b`
      )
      .then((res) => {
        setAddress(res.data);
      });
  }, []);

  const handleLogout = () => {
    Swal.fire({
      icon: "info",
      title: "Logging Out",
      text: "Are you sure you want to log out?",
      showCancelButton: true,
      confirmButtonText: "Yes, Log Out",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("userData");
        navigate("/login");
        window.location.reload();
        Swal.fire({
          icon: "success",
          title: "Logged Out",
          text: "You have been logged out successfully.",
        });
      }
    });
  };

  console.log("address", address);
  console.log("address2222222", address.billing);

  return (
    <Fragment>
      <div className="ml-[150px]">
        <div className="flex w-[80vw] gap-4">
          <div className="w-[60vw]  p-4">
            <div>
              The following addresses will be used on the checkout page by
              default.
            </div>
            <div className="mt-[100px] flex">
              <div className="bg-slate-100 p-4 rounded m-[20px] p-4">
                <div className="m-4">
                  {address.first_name} {address.last_name}
                  <br />
                  <div className="mt-4">
                    {" "}
                    {address && address && (
                      <div> {address.billing.address_1}</div>
                    )}
                  </div>
                  <div className="mt-4">
                    {" "}
                    {address && address && (
                      <div> {address.billing.postcode}</div>
                    )}
                  </div>
                </div>

                <div></div>
              </div>

              <Link to="/update-address">
                {" "}
                <div>
                  <FaEdit className="text-red-500 w-[50px]" />
                </div>
              </Link>
              <div className="bg-slate-100 p-4 rounded ml-[150px]">
                <div className="bg-slate-100 p-4 rounded m-[20px] p-4">
                  <div className="m-4">
                    {address.first_name} {address.last_name}
                  </div>
                  <div className="m-4">
                    <div className="mt-4">
                      {" "}
                      {address && address && (
                        <div> {address.billing.address_1}</div>
                      )}
                    </div>
                    <div className="mt-4">
                      {" "}
                      {address && address && (
                        <div> {address.billing.postcode}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <Link to={"/update-address"}>
                {" "}
                <div>
                  <FaEdit className="text-red-500 w-[50px]" />
                </div>
              </Link>
            </div>
          </div>
          <div className="w-[20vw] border text-red-500">
            <hr />
            <Link to="/dashboard">
              {" "}
              <div className="flex ml-4 mt-6 p-2">
                Dashboard <AiFillDashboard className="ml-[90px] w-[50px]" />
              </div>
            </Link>
            <hr />
            <Link to="/orders">
              {" "}
              <div className="flex ml-4 mt-6 p-2">
                Orders <BiSolidCartDownload className="ml-[120px] w-[50px]" />
              </div>
            </Link>
            <hr />
            <Link to="/address">
              {" "}
              <div className="flex ml-4 mt-6 p-2">
                Addresses <IoIosHome className="ml-[100px] w-[50px]" />
              </div>
            </Link>
            <hr />
            <Link to="/account">
              {" "}
              <div className="flex ml-4 mt-6 p-2">
                Account Details <CgProfile className="ml-[60px] w-[50px]" />
              </div>
            </Link>
            <hr />
            <div className="flex ml-4 mt-6 p-2" onClick={handleLogout}>
              <Link>Log Out</Link>{" "}
              <IoMdLogOut className="ml-[120px] w-[50px]" />
            </div>
            <hr />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
