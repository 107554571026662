import React, { Fragment } from "react";
import { Link } from "react-router-dom";


export default function PageNotFound(){


    return(

        <Fragment>

        <h1 className=" text-red-900 font-bold text-4xl ml-[400px] mt-[100px] mb-[100px]"><span><Link to='/'>Oops! Page Not Found Go To Home</Link></span></h1>

        </Fragment>
    )
}