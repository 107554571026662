import React, { Fragment, useEffect, useState } from "react";
import { format } from 'date-fns';
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiFillDashboard } from "react-icons/ai";
import { BiSolidCartDownload } from "react-icons/bi";
import { IoIosHome } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import { IoMdLogOut } from "react-icons/io";
import Swal from "sweetalert2";



export default function ViewOrder() {
  const navigate = useNavigate();
  const params = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [subtotal, setSubtotal] = useState(0);
  const [billingAddress, setBillingAddress] = useState("");
  const [orderId, setOrderId] = useState(null);
  const [product, setProduct] = useState([]);
  const [date, setDate] = useState(null);
  const [email, setEmail] = useState(null);
  const [total, setTotal] = useState(null);
  const [first_name, setFirst_name] = useState(null);
  const [last_name, setLast_name] = useState(null);
  const [address_1, setAddress_1] = useState(null);
  const [address_2, setAddress_2] = useState(null);
  const [city, setCity] = useState(null);
  const [phone, setPhone] = useState(null);
  const [postcode, setPostcode] = useState(null);
  const [method_title, setMethod_title] = useState(null);
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [orderByID, setOrderByID] = useState("");
  // const [total, setTotal]

  const handleLogout = () => {
    Swal.fire({
      icon: "info",
      title: "Logging Out",
      text: "Are you sure you want to log out?",
      showCancelButton: true,
      confirmButtonText: "Yes, Log Out",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // Clear userData from localStorage
        localStorage.removeItem("userData");
        // Redirect to the login page
        navigate("/login");
        // Show logout success alert
        Swal.fire({
          icon: "success",
          title: "Logged Out",
          text: "You have been logged out successfully.",
        });
      }
    });
  };

  useEffect(() => {
    axios
      .get(
        `https://checkndeal.skilldevelopers.in/wp-json/wc/v3/orders/${params.id}?consumer_key=ck_5cb86662b3f148136f41dede89999264419cba87&consumer_secret=cs_52258b1d43f07c649c19698bd77974e99648772b&customer=3261`
      )
      .then((res) => {
        setOrderByID(res.data);
        setOrderId(res.data.number);
        setName(res.data.line_items[0].name);
        setQuantity(res.data.line_items[0].quantity);
        setTotal(res.data.line_items[0].shipping_total);
        // Parse and format date_created
        const parsedDate = new Date(res.data.date_created);
        if (!isNaN(parsedDate.getTime())) { // Check if parsedDate is valid
          setDate(format(parsedDate, "EEEE, MMMM dd, yyyy HH:mm:ss"));
        } else {
          setDate("Invalid Date"); // Handle invalid date
        }
        setEmail(res.data.billing.email);
        setFirst_name(res.data.billing.first_name);
        setLast_name(res.data.billing.last_name);
        setAddress_1(res.data.billing.address_1);
        setAddress_2(res.data.billing.address_2);
        setCity(res.data.billing.city);
        setMethod_title(res.data.shipping_lines.method_title);
        setPhone(res.data.billing.phone);
        setPostcode(res.data.billing.postcode);
        setTotal(res.data.shipping_total);
        setOrderDetails(res.data);
        setSubtotal(res.data.total - res.data.shipping_total);
      })
      .catch((error) => {
        console.error("Error fetching orders:", error);
      });
  }, []);
  
  console.log("Orderssssssssssssssssss", orderByID);
  //   console.log("method_title", name);

  return (
    <Fragment>
      <div className="ml-[150px] mt-6">
        <div className="flex">
          <div>
            
            <div>Order #{orderByID.number} was placed on {orderByID.date_created}{" "}
            and is currently {orderByID.status}</div>
            {/* <td>{format(new Date(item.date_created), 'dd MMMM yyyy')}</td> */}
            {/* <div className="mt-[150px]">1. {orderByID.date_created}</div> */}
          </div>
          <div className="w-[20vw] border text-red-500 ml-[150px]">
            <hr />
            <Link to="/dashboard">
              {" "}
              <div className="flex ml-4 mt-6 p-2">
                Dashboard <AiFillDashboard className="ml-[90px] w-[50px]" />
              </div>
            </Link>
            <hr />
            <Link to="/orders">
              {" "}
              <div className="flex ml-4 mt-6 p-2">
                Orders <BiSolidCartDownload className="ml-[120px] w-[50px]" />
              </div>
            </Link>
            <hr />
            <Link to="/address">
              {" "}
              <div className="flex ml-4 mt-6 p-2">
                Addresses <IoIosHome className="ml-[100px] w-[50px]" />
              </div>
            </Link>
            <hr />
            <Link to="/account">
              {" "}
              <div className="flex ml-4 mt-6 p-2">
                Account Details <CgProfile className="ml-[60px] w-[50px]" />
              </div>
            </Link>
            <hr />
            <div className="flex ml-4 mt-6 p-2" onClick={handleLogout}>
              <Link>Log Out</Link>{" "}
              <IoMdLogOut className="ml-[120px] w-[50px]" />
            </div>
            <hr />
          </div>
        </div>

        {orderDetails && (
          <div>
            <table className="w-[1100px] mt-6 -ml-[15px] text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 bg-slate-300 p-2 ">
              <thead className="text-xs text-white uppercase bg-red-700 dark:bg-gray-700 dark:text-gray-400 ">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Product
                  </th>

                  <th scope="col" className="px-6 py-3">
                    Total
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white p-2">
                <td className="p-2">
                  {name}&nbsp;
                  <span className="font-bold">X&nbsp;{quantity}</span>
                </td>
                <td>{total}</td>
              </tbody>
            </table>
          </div>
        )}
        {orderDetails && (
          <div className="flex w-[1100px] mt-12 gap-4">
            <div className="bg-red-600 w-[500px] p-2">
              <div className="mt-8 mb-2">Subtotal</div>
              {/* <div className="mt-8 mb-2">Shipping</div> */}
              {/* <div className="mt-8 mb-2">Payment Method</div> */}
              <div className="mt-8 mb-2">Total</div>
            </div>
            <div className="w-[500px] p-2">
              <div className="mt-8 mb-2">{total}</div>
              {/* <div className="mt-8 mb-2">{method_title}</div> */}
              {/* <div></div> */}
              <div className="mt-[40px]">{total}</div>
            </div>
          </div>
        )}

        <div className="flex mt-12">
          <div>
            <p className="text-4xl">Billing Address</p>
            {product && (
              <>
                <div className="mt-6">
                  {first_name}&nbsp;{last_name}{" "}
                </div>
                <div className="mt-6">
                  {address_1}&nbsp;{address_2}
                </div>
                <div className="mt-6">{postcode}</div>
                <div className="mt-6">{phone}</div>
                <div className="mt-6">{email} </div>
              </>
            )}
          </div>
          <div className="ml-[300px]">
            <h1 className="text-4xl">Shipping address</h1>
            {product && (
              <>
                <div className="mt-6">
                  {first_name}&nbsp;{last_name}{" "}
                </div>
                <div className="mt-6">
                  {address_1}&nbsp;{address_2}
                </div>
                <div className="mt-6">{postcode}</div>
                <div className="mt-6">{phone}</div>
                <div className="mt-6">{email} </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
