import axios from "axios";
import countryAllData from "country-all-data";
import React, { Fragment, useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Home from "./Home";
import Card from "./Card";
import { v4 as uuidv4 } from "uuid";
import Country from "./Country.json";

export default function Checkout() {
  const [countriesArr, setCountriesArr] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [mpesaPaymentInProgress, setMpesaPaymentInProgress] = useState(false);
  const [mpesaPaymentError, setMpesaPaymentError] = useState(null);
  const [mpesaPaymentSuccess, setMpesaPaymentSuccess] = useState(false);
  const [razorpayLoaded, setRazorpayLoaded] = useState(false);
  const [option, setOption] = useState("option1");


  const [values, setValues] = useState({
    country: "",
    city: "",
    address_1: "",
    first_name: "",
    last_name: "",
    address_2: "",
    postcode: "",
    phone: "",
    email: "",
    state: "",
    instruction: "",
    product_id: "",
    quantity: "",
    variation_id: "",
    total: "",
    name:"",
    variation:"",
    customer_id:"",
    // userData:""
  });

  
  const navigate = useNavigate();

  useEffect(() => {


     // Retrieve user data from local storage
  const userData = JSON.parse(localStorage.getItem("userData"));
  console.log("userdataaaaaaaaaaaaaaaaa",userData.ID);

  // Update the state with user data
  if (userData) {
    setValues(userData);
  }


    // Retrieve cart items from local storage
    const storedCartItems = JSON.parse(localStorage.getItem("cart")) || [];
    setCartItems(storedCartItems);

    // Load Razorpay script
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onload = () => setRazorpayLoaded(true);
    document.body.appendChild(script);

    return () => {
      // Clean up script after component unmounts
      document.body.removeChild(script);
    };
  }, []);

  // Calculate subtotal
  const subtotal = cartItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  // Assuming tax is 10% of the subtotal
  // const tax = subtotal * 0.1;

  // Calculate total
  const total = subtotal 



  console.log("CARTTTTTTTTTTTTTTTTTTTT",cartItems);
  // Save Address Method
 
  const saveData = async (e) => {
    e.preventDefault();
  
    // Retrieve customer ID from local storage
    const userData = JSON.parse(localStorage.getItem("userData"));
    const userId = userData ? userData.ID : null;
  
    const data = {
      billing: {
        first_name: values.first_name,
        last_name: values.last_name,
        address_1: values.address_1,
        address_2: values.address_2,
        city: values.city,
        state: values.state,
        postcode: values.postcode,
        country: values.country,
        email: values.email,
        phone: values.country,
      },
      shipping: {
        first_name: values.first_name,
        last_name: values.last_name,
        address_1: values.address_1,
        address_2: values.address_2,
        city: values.city,
        state: values.state,
        postcode: values.postcode,
        country: values.country,
      },
      line_items: cartItems.map(item => ({
        product_id: item.id,
        variation_id: item.variation_id, // Make sure this is correct
        quantity: item.quantity,
      })),
      shipping_lines: [
        {
          method_id: "flat_rate",
          method_title: "Flat Rate",
          total: total.toFixed(2),
        },
      ],
      customer_id: userId, // Include customer ID here
    };
  
    axios
      .post(
        "https://checkndeal.skilldevelopers.in/wp-json/wc/v3/orders?consumer_key=ck_5cb86662b3f148136f41dede89999264419cba87&consumer_secret=cs_52258b1d43f07c649c19698bd77974e99648772b&per_page=45",
        data
      )
      .then((res) => {
        // Handle response here if needed
        console.log("Order created successfully:", res.data);
        // Optionally, you can clear cart items or perform any other actions here
      })
      .catch((error) => console.error("Error while saving data:", error));
  };
  

  // const totals = tax

  // Payment Method
  // Payment Method
  const checkoutHandler = async () => {
    if (!razorpayLoaded) {
      console.error("Razorpay script not loaded");
      return;
    }


    try {
      // Send the order data to the API
      const response = await axios.post(
        "https://checkndeal.skilldevelopers.in/wp-json/wc/v3/orders?consumer_key=ck_5cb86662b3f148136f41dede89999264419cba87&consumer_secret=cs_52258b1d43f07c649c19698bd77974e99648772b&per_page=45"
      );

      // Handle the response here if needed

      // Proceed with Razorpay payment
      const options = {
        key: "rzp_test_4cMczKOVEClZP1",
        amount: total * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Arun Saini",
        // order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        description: "Test Transaction",
        image: "https://avatars.githubusercontent.com/u/149789103?v=4",
        prefill: {
          name: "Arun Saini",
          email: "arun.kumar@example.com",
          contact: "9522881015",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#5D31ED",
        },
      };

      const razorpay = new window.Razorpay(options);
      razorpay.open();

      navigate("/ordersuccess");
    } catch (error) {
      console.error("Error while processing the order:", error);
      // Handle error here
    }
  };

  
  const handleOptionChange = (e) => {
    setOption(e.target.value);
  };

  // Assuming total is calculated already
  const deliveryCharge = total  // 
  const totalAmount = total + deliveryCharge;



  return (
    <Fragment>
      <h1 className="text-2xl p-2 ml-[150px]">Where should we deliver to?</h1>
      <div className="ml-[150px] w-[80vw] mt-6 flex ">
        <div className="flex p-4 w-[90vw] gap-4">
          <div>
            <div className="bg-slate-200 p-4 flex gap-6">
              <div>Click & Collect</div>
              <label>
                <input
                  type="radio"
                  name="option"
                  value="option1"
                  checked={option === "option1"}
                  onChange={handleOptionChange}
                />{" "}
              </label>
            </div>
            <br />
            <div className="bg-slate-200 p-4 gap-6 flex">
              <div> Deliver To My Address</div>
              <label>
                <input
                  type="radio"
                  name="option"
                  value="option2"
                  checked={option === "option2"}
                  onChange={handleOptionChange}
                />{" "}
              </label>
            </div>

            {option === "option1" && (
              <div>
                <form onSubmit={saveData}>
                  <div lassName=" ml-4 w-[39vw] p-4 border">
                    <div className="flex gap-6">
                      <div>
                        {" "}
                        <div className="mt-6">
                          <select className="w-[500px] h-10">
                            <option>Please Select A Store</option>
                            <option>
                              NAIROBI, NO.30 LUNGA LUNGA RD, INDUSTRIAL AREA
                            </option>
                            <option>MOMBASA, MACHAKOS STREET</option>
                          </select>
                          <p className="mt-8">Who will collect? </p>
                          <div className="flex">
                            <div>
                              <input
                               id="first_name"
                               name="first_name"
                               required
                               onChange={(e) =>
                                 setValues({
                                   ...values,
                                   first_name: e.target.value,
                                 })
                               }
                                type="text"
                                className="w-[200px]"
                                placeholder="First Name"
                              />
                            </div>
                            <div>
                              <input
                                id="last_name"
                                name="last_name"
                                required
                                onChange={(e) =>
                                  setValues({
                                    ...values,
                                    last_name: e.target.value,
                                  })
                                }
                                type="text"
                                placeholder="Last Name"
                                className="ml-4 w-[200px]"
                              />
                            </div>
                          </div>
                          <div className="mt-12 flex">
                            <div className="">
                              <input
                                 id="email"
                                 name="email"
                                 required
                                 onChange={(e) =>
                                   setValues({ ...values, email: e.target.value })
                                 }
                                type="email"
                                className="w-[200px]"
                                placeholder="Email"
                              />
                            </div>
                            <div>
                              <input
                              minLength={10}
                              maxLength={10}
                                type="tel"
                                id="phone"
                                name="phone"
                                required
                                onChange={(e) =>
                                  setValues({ ...values, phone: e.target.value })
                                }
                                placeholder="Phone Number"
                                className="ml-4 w-[200px]"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <div className=" w-[39vw] p-4">
                            <div className="flex p-2">
                              <h1 className="text-2xl">YOUR ORDER</h1>
                              {/* <p className="ml-[70px]">Flat rate: KSh 500.00</p> */}
                            </div>

                            <div>
                              <table className="w-[520px] mt-6 -ml-[15px] text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 bg-slate-300 p-2 ">
                                <thead className="text-xs text-white uppercase bg-red-700 dark:bg-gray-700 dark:text-gray-400 ">
                                  <tr>
                                    <th scope="col" className="px-6 py-3">
                                      Product
                                    </th>

                                    <th scope="col" className="px-6 py-3">
                                      Subtotal
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {cartItems.map((item, index) => (
                                    <tr
                                      key={index}
                                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                    >
                                      <th
                                        scope="row"
                                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                      >
                                        {item.name} X {item.quantity}
                                      </th>

                                      <td className="px-6 py-4">
                                        {item.price * item.quantity}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                                <div className="flex mt-10 ml-4">
                                  <div className="text-xl text-red-700">
                                    Subtotal{" "}
                                  </div>
                                  <p className="ml-[200px]">
                                    {total.toFixed(2)}
                                  </p>
                                </div>

                                <div className="flex mt-10 ml-4">
                                  <div className="text-xl text-red-700">
                                    Delivery{" "}
                                  </div>
                                  <p className="ml-[200px]"></p>
                                </div>

                                <div className="flex mt-10 ml-4">
                                  <div className="text-xl text-red-700">
                                    Total{" "}
                                  </div>
                                  <p className="ml-[200px] mb-4">
                                    {total.toFixed(2)}
                                    <p className="text-xs">
                                      {/* (includes {tax.toFixed(2)} Tax) */}
                                    </p>
                                  </p>
                                </div>
                              </table>

                              <p className="mt-6 mb-6 font-semibold">
                                Payment Method
                              </p>
                              <div className="">
                                <div className="p-6">{/* <Home /> */}</div>
                                <div>
                                  <input type="checkbox" />
                                  &nbsp;&nbsp; I have read and agree to the
                                  website{" "}
                                  <span className="text-red-500">
                                    <Link
                                      to="/terms-of-service/"
                                      target="_blank"
                                    >
                                      terms and conditions
                                    </Link>
                                  </span>{" "}
                                </div>
                              </div>
                              <div className="mt-6">
                                <input type="checkbox" />
                                &nbsp;&nbsp; Your personal data will be used to
                                process your order,support your experience
                                throughout this website,and for other purposes
                                described in our{" "}
                                <Link
                                  className="text-red-500"
                                  to="/privacy-policy/"
                                  target="_blank"
                                >
                                  Privacy Policy{" "}
                                </Link>
                              </div>
                              <div>
                                <div className="mt-6">
                                  <input type="checkbox" />
                                  &nbsp;&nbsp;
                                  <Link
                                    className="text-red-500"
                                    to="/consent-notice/"
                                  >
                                    Consent Notice{" "}
                                  </Link>
                                </div>
                              </div>

                              <div className="flex gap-12 mt-14">
                                <div>
                                  <button
                                    className="bg-red-600 text-white p-4 font-bold"
                                    onClick={() => checkoutHandler()}
                                  >
                                    {mpesaPaymentInProgress
                                      ? "Processing..."
                                      : "Confirm Order"}
                                  </button>
                                </div>
                                <div>
                                  <Link to="/">
                                    <button className="bg-red-600 text-white p-4 ">
                                      Continue Shopping
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <button className="bg-red-500 text-white p-4" onClick={saveData}>
              Save Address
            </button> */}
                </form>
              </div>
            )}

            {/* Option 222222222222222222222222222222222222222222222222222222222222222222222 */}
            {option === "option2" && (
              <div className="flex">
                <form onSubmit={saveData}>
                  <div className="flex">
                    <div className="mt-12  ">
                      {/* <div className="bg-slate-200 p-2"> Deliver To My Address</div> */}

                      <div>
                        <select required className="mt-6 w-[480px]">
                          {Country.map((country) => {
                            return (
                              <option
                                required
                                id="country"
                                name="country"
                                type="country"
                                onChange={(e) =>
                                  setValues({
                                    ...values,
                                    country: e.target.value,
                                  })
                                }
                              >
                                {country.name}
                              </option>
                            );
                          })}
                        </select>

                        <div>
                          <input
                            id="city"
                            name="city"
                            required
                            onChange={(e) =>
                              setValues({ ...values, city: e.target.value })
                            }
                            type="text"
                            placeholder="Enter City Name"
                            className="mt-4 w-[480px]"
                          />
                        </div>

                        <div>
                          <input
                            id="address_1"
                            name="address_1"
                            required
                            onChange={(e) =>
                              setValues({
                                ...values,
                                address_1: e.target.value,
                              })
                            }
                            type="text"
                            placeholder="Enter Area Name"
                            className="mt-4 w-[480px]"
                          />
                        </div>

                        <div className="flex w-[500px] mt-6">
                          <div>
                            <input
                              id="first_name"
                              name="first_name"
                              required
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  first_name: e.target.value,
                                })
                              }
                              type="text"
                              className="w-[200px]"
                              placeholder="First Name"
                            />
                          </div>
                          <div>
                            <input
                              id="last_name"
                              name="last_name"
                              required
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  last_name: e.target.value,
                                })
                              }
                              type="text"
                              placeholder="Last Name"
                              className="ml-4 w-[200px]"
                            />
                          </div>
                        </div>

                        <div>
                          <input
                            id="address_2"
                            name="address_2"
                            required
                            onChange={(e) =>
                              setValues({
                                ...values,
                                address_2: e.target.value,
                              })
                            }
                            type="text"
                            placeholder="House Number And Street Name"
                            className="mt-4 w-[480px]"
                          />
                        </div>

                        <div>
                          <input
                            id="postcode"
                            name="postcode"
                            required
                            onChange={(e) =>
                              setValues({ ...values, postcode: e.target.value })
                            }
                            type="text"
                            placeholder="Post Code"
                            className="mt-4 w-[480px]"
                          />
                        </div>

                        <div>
                          <input
                            id="phone"
                            name="phone"
                            required
                            onChange={(e) =>
                              setValues({ ...values, phone: e.target.value })
                            }
                            type="number"
                            placeholder="Mobile Number"
                            className="mt-4 w-[480px]"
                          />
                        </div>

                        <div>
                          <input
                            id="email"
                            name="email"
                            required
                            onChange={(e) =>
                              setValues({ ...values, email: e.target.value })
                            }
                            type="email"
                            placeholder="Email"
                            className="mt-4 w-[480px]"
                          />
                        </div>

                        <div>
                          <textarea
                            id="instruction"
                            name="instruction"
                            required
                            onChange={(e) =>
                              setValues({
                                ...values,
                                instruction: e.target.value,
                              })
                            }
                            placeholder="Instruction"
                            className="mt-4 w-[480px]"
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className=" w-[39vw] p-4">
                        <div className="flex p-2">
                          <h1 className="text-2xl">YOUR ORDER</h1>
                          {/* <p className="ml-[70px]">Flat rate: KSh 500.00</p> */}
                        </div>

                        <div>
                          <table className="w-[520px] mt-6 -ml-[15px] text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 bg-slate-300 p-2 ">
                            <thead className="text-xs text-white uppercase bg-red-700 dark:bg-gray-700 dark:text-gray-400 ">
                              <tr>
                                <th scope="col" className="px-6 py-3">
                                  Product
                                </th>

                                <th scope="col" className="px-6 py-3">
                                  Subtotal
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {cartItems.map((item, index) => (
                                <tr
                                  key={index}
                                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                >
                                  <th
                                    scope="row"
                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                  >
                                    <Link to={"/product/" + item.id}>
                                      {" "}
                                      {item.name}&nbsp;-&nbsp;{item.color}&nbsp;
                                      X {item.quantity}
                                    </Link>
                                  </th>

                                  <td className="px-6 py-4">
                                    {item.price * item.quantity}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            <div className="flex mt-10 ml-4">
                              <div className="text-xl text-red-700">
                                Subtotal{" "}
                              </div>
                              <p className="ml-[200px]">{total}</p>
                            </div>

                            <div className="flex mt-10 ml-4">
                              <div className="text-xl text-red-700">
                                Delivery{" "}
                              </div>
                              {/* <p className="ml-[200px]">
                                KSh {deliveryCharge.toFixed(2)}
                              </p> */}
                            </div>

                            <div className="flex mt-10 ml-4">
                              <div className="text-xl text-red-700">Total </div>
                              <p className="ml-[200px] mb-4">
                              {total.toFixed(2)}
                                <p className="text-xs">(includes Tax)</p>
                              </p>
                            </div>
                          </table>

                          <p className="mt-6 mb-6 font-semibold">
                            Payment Method
                          </p>
                          <div className="">
                            <div className="p-6">{/* <Home /> */}</div>
                            <div>
                              <input required type="checkbox" />
                              &nbsp;&nbsp; I have read and agree to the website{" "}
                              <span className="text-red-500">
                                <Link to="/terms-of-service/" target="_blank">
                                  terms and conditions
                                </Link>
                              </span>{" "}
                            </div>
                          </div>
                          <div className="mt-6">
                            <input required type="checkbox" />
                            &nbsp;&nbsp; Your personal data will be used to
                            process your order,support your experience
                            throughout this website,and for other purposes
                            described in our{" "}
                            <Link
                              className="text-red-500"
                              to="/privacy-policy/"
                              target="_blank"
                            >
                              Privacy Policy{" "}
                            </Link>
                          </div>
                          <div>
                            <div className="mt-6">
                              <input required type="checkbox" />
                              &nbsp;&nbsp;
                              <Link
                                className="text-red-500"
                                to="/consent-notice/"
                              >
                                Consent Notice{" "}
                              </Link>
                            </div>
                          </div>

                          <div className="flex gap-12 mt-14">
                            <div>
                              <button
                                className="bg-red-600 text-white p-4 font-bold"
                                onClick={() => checkoutHandler()}
                              >
                                {mpesaPaymentInProgress
                                  ? "Processing..."
                                  : "Confirm Order"}
                              </button>
                            </div>
                            <div>
                              <Link to="/">
                                <button className="bg-red-600 text-white p-4 ">
                                  Continue Shopping
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}