import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { TfiReload } from "react-icons/tfi";
import ReactStars from "react-rating-stars-component";
import CursorZoom from "react-cursor-zoom";
import { BsEmojiSmileFill } from "react-icons/bs";
import ReactImageZoom from 'react-image-zoom';
import Zoom from 'react-img-zoom'



export default function ProductDetails({ id }) {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");

  const params = useParams();
  const navigate = useNavigate();

  const [user, setUser] = useState([]);
  const [product, setProduct] = useState([]);
  const [review, setReview] = useState([]);
  const [color, setColor] = useState([]);
  const [colorCode, setColorCode] = useState([]);
  const [colorCodes, setColorCodes] = useState([]);
  const [productColor, setProductColor] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [productImage, setProductImage] = useState();
  const [selectedColorName, setSelectedColorName] = useState("IVORY"); // Initialize with default color name

  const [quantity, setQuantity] = useState(1); // Initialize quantity with 1

  // Function to handle quantity change
  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value);
    if (!isNaN(newQuantity) && newQuantity > 0) {
      setQuantity(newQuantity);
    }
  };

  // Add To Cart
  // Add To Cart
  const addToCartApi =
    "https://checkndeal.skilldevelopers.in/wp-json/wc/store/cart/items?consumer_key=ck_20d468c070adc2aef1512c9731275b475c51d7da&consumer_secret=cs_a440fcd3b8fabc5525f83f92a0a984f3dee641b1&id=5112&quantity=2";


    const addToCarts = () => {
      // Check if the product is out of stock
      if (productImage && productImage.stock_status === "outofstock") {
        // Product is out of stock, prevent adding to cart
        alert("This product is currently out of stock.");
        
        return; // Exit function
      }
    
      // Product is in stock, proceed with adding to cart
      const existingCartItems = JSON.parse(localStorage.getItem("cart")) || [];
    
      const newCartItem = {
        id: product.id,
        name: product.name,
        price: product.price,
        quantity: quantity, // Use the quantity state variable here
        color: selectedColorName, // Save selected color name
        image: productImage.image.src, // Save product image
        // variation_id: selectedColorName,
      };
    
      const updatedCartItems = [...existingCartItems, newCartItem];
    
      localStorage.setItem("cart", JSON.stringify(updatedCartItems));
    
      navigate("/cart/:id");
    
      // Reload the page
      window.location.reload();
    };
    
  const handleDecrement = (index) => {
    const updatedCartItems = [...cartItems];
    if (updatedCartItems[index].quantity > 1) {
      updatedCartItems[index].quantity--;
      setCartItems(updatedCartItems);
    }
  };

  const handleIncrement = (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems[index].quantity++;
    setCartItems(updatedCartItems);
  };

  // const d = new Date();
  let obj = new Date();
  let day = obj.getUTCDate();
  let month = obj.getUTCMonth() + 1;
  let year = obj.getUTCFullYear();

  function formatNumber() {
    return `${day}-${month}-${year}`;
  }

  // Add Review Method
  const [values, setValues] = useState({
    product_id: params.id,
    rating: "",
    review: "",
    reviewer: "",
    reviewer_email: "",
  });

  const addReview = (e) => {
    e.preventDefault();
    axios
      .post(
        "https://checkndeal.skilldevelopers.in/wp-json/wc/v3/products/reviews?consumer_key=ck_5cb86662b3f148136f41dede89999264419cba87&consumer_secret=cs_52258b1d43f07c649c19698bd77974e99648772b",
        values
      )
      .then((res) => setValues(res.values));
  };

  useEffect(() => {
    // Get Product Review
    axios
      .get(
        `https://checkndeal.skilldevelopers.in/wp-json/wc/v3/products/reviews?product=${params.id}&consumer_key=ck_5cb86662b3f148136f41dede89999264419cba87&consumer_secret=cs_52258b1d43f07c649c19698bd77974e99648772b`
      )
      .then((res) => {
        setReview(res.data);
      });

    // Get Product By ID
    axios
      .get(
        `https://checkndeal.skilldevelopers.in/wp-json/wc/v3/products/${params.id}?consumer_key=ck_5cb86662b3f148136f41dede89999264419cba87&consumer_secret=cs_52258b1d43f07c649c19698bd77974e99648772b`
      )
      .then((res) => {
        setProduct(res.data);
      });
    console.log("");

    // Get Product Color
    axios
      .get(
        `https://checkndeal.skilldevelopers.in/wp-json/wc/v3/products/${params.id}?consumer_key=ck_5cb86662b3f148136f41dede89999264419cba87&consumer_secret=cs_52258b1d43f07c649c19698bd77974e99648772b`
      )
      .then((res) => {
        setColor(res.data.attributes[0].options);
      })
      .catch((error) => {
        console.error("Error fetching color:", error);
      });

    // Get Color Value Or Color Code
    axios
      .get(
        `https://checkndeal.skilldevelopers.in/wp-json/wc/v3/products/${params.id}?consumer_key=ck_5cb86662b3f148136f41dede89999264419cba87&consumer_secret=cs_52258b1d43f07c649c19698bd77974e99648772b`
      )
      .then((res) => {
        setColorCode(res.data);

        console.log("Color Name:", res.data.price);
      })
      .catch((error) => {
        console.error("Error fetching color:", error);
      });

    // Get  Color Code
    axios
      .get("https://checkndeal.skilldevelopers.in/wp-json/myplugin/v3/colors")
      .then((res) => {
        setColorCodes(res.data);

        console.log("Color Codes Here:", res.data);
      })
      .catch((error) => {
        console.error("Error fetching color:", error);
      });

    // Variation Api Here
    // Get  Color Code
    axios
      .get(
        `https://checkndeal.skilldevelopers.in/wp-json/wc/v3/products/${params.id}/variations?consumer_key=ck_5cb86662b3f148136f41dede89999264419cba87&consumer_secret=cs_52258b1d43f07c649c19698bd77974e99648772b`
      )
      .then((res) => {
        setProductColor(res.data);

        console.log("Variation Api:", res.data);
        setProductImage(res.data[0]);
      })
      .catch((error) => {
        console.error("Error fetching color:", error);
      });
  }, []);

  const ratingChanged = (newRating) => {
    setValues({ ...values, rating: newRating });
  };

  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();

    return str.replace(/(<([^>]+)>)/gi, "");
  }
  // Function to handle color change
  const changeColor = (e) => {
    const selectedColor = e.target.value;
    setSelectedColorName(selectedColor);
    setProductImage(
      productColor.find(
        (product) => product.attributes[0].option === selectedColor
      )
    );
  };

  console.log("Variation Apiiiiiiiiiiiiiiiiiiiiiiiii:", product);

  return (
    <Fragment>
      <h2></h2>

      <div className="ml-[60px] mt-8 flex gap-4">
        <div className="h-[500px] w-[900px]">
          {/* {product.images && (
            <CursorZoom
              image={{
                src: product.images[0].src,
                width: 400,
                height: 300,
              }}
              zoomImage={{
                src: product.images[0].src,
                width: 1200,
                height: 1200,
              }}
              cursorOffset={{ x: 80, y: -80 }}
            />
          )} */}
          {productImage && (
            <>
              {/* <Magnifier src={productImage.image.src} width={500} /> */}
              {/* <ReactImageZoom src={productImage.image.src} /> */}
              <Zoom
  img={productImage.image.src}
  zoomScale={2.5}
  width={400}
  height={400}
/>

              {/* <div>
                {productImage.stock_quantity}
              </div> */}
            </>
          )}
        </div>
        <div className="mr-[40px] h-[500px] w-[500px]">
          <h1 className="ml-6 mt-4 text-lg">{product.name}</h1>
          <h3 className="ml-6 mt-4">
            KSh <span className="text-red-500">{product.price}</span>{" "}
          </h3>
          <div className="ml-6 mt-2 border">
            <h4 className="ml-6 mt-2">Colour : {selectedColorName}</h4>

            {/* Color Code Start */}

            <div className="mb-4 ml-4 mt-4 flex  gap-4 text-xs">
              {color.map((colorName, index) => (
                <div className="border p-1" key={index}>
                  {/* <div>{colorName}</div> */}
                  <div>
                    <input
                      className="hidden"
                      type="radio"
                      name="colorName"
                      value={colorName}
                      id={colorName}
                      onChange={(e) => changeColor(e)}
                    />
                    <label
                      style={{
                        background: colorCodes[colorName],
                        width: "12px",
                        height: "12px",
                        display: "block",
                      }}
                      for={colorName}
                    ></label>
                  </div>
                </div>
              ))}
            </div>
            {/* Color Code End */}
            <div className="mb-2 ml-12 mt-2 flex text-red-600">
              <button className="flex">
                <TfiReload />
                &nbsp;&nbsp;Clear
              </button>
            </div>
          </div>
          {productImage && (
            <div className="text-green-700 mt-2 ml-8 mb-2 flex gap-4">
              <BsEmojiSmileFill /> {productImage.stock_quantity}{" "}
              {productImage.stock_status == "outofstock" ? (
                <div className="text-red-700">{productImage.stock_status}</div>
              ) : (
                <div className="text-green-700">
                  {productImage.stock_status}
                </div>
              )}
            </div>
          )}
          <div className="ml-6 mt-2 w-[160px]  bg-green-500 mb-4">
            SELECT THE COLOUR.
          </div>
          <div className="ml-6 mt-1 flex gap-4">
            <div>{/* <input className="w-[50px] p-1" type="number" /> */}</div>
            <div className="flex gap-4">
              <input
                type="number"
                className="w-[50px] p-2 h-[40px]"
                value={quantity}
                onChange={handleQuantityChange}
              />
              <Link>
                <button
                  onClick={addToCarts}
                  className={`mb-6 rounded bg-red-600 p-2 text-white ${productImage && productImage.stock_status === 'outofstock' ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={productImage && productImage.stock_status === 'outofstock'}
                >
                  ADD TO CART
                </button>
              </Link>
            </div>
          </div>
          <hr />

          <div className="ml-6 w-[200px] bg-slate-100">
            <div className="flex p-2">
              <pre>L x W x H</pre>
            </div>
            <div className="flex p-2">
              {product.dimensions && (
                <p>
                  {product.dimensions.length} x {product.dimensions.width} x{" "}
                  {product.dimensions.height}mm
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className=" mt-6 gap-4 ">
        <div className="ml-[460px] w-[200px] bg-blue-200 p-2">
          Reviews {review.length}
        </div>
        {review.length == 0 ? (
          <div className="-mt-10 ml-[700px] w-[400px] rounded bg-red-600 p-4 text-center text-white">
            There are no reviews yet.
          </div>
        ) : (
          <div>
            {" "}
            {review.map((item) => (
              <div>
                <div className="ml-[700px] mt-4 flex">
                  <div>
                    <img
                      className="mb-4 w-[60px]  p-2"
                      src="https://static.vecteezy.com/system/resources/thumbnails/002/318/271/small/user-profile-icon-free-vector.jpg"
                      alt="ARun"
                    />
                  </div>
                  <div className="w-[300px]">
                    {" "}
                    <b className="-mt-[60px] ml-[30px]  ">
                      {item.reviewer}
                      <br />
                      <span className="ml-6 mt-4 font-light text-slate-500 mt-2	">
                        {formatNumber(item.date_created_gmt)}
                      </span>
                      <br />
                      <span className="ml-6 mt-4 mt-[30px]	font-light mt-[50px]">
                        {removeTags(item.review)}
                      </span>
                    </b>
                  </div>
                  <div className="ml-[100px]">
                    <div>
                      <ReactStars
                        count={item.rating}
                        size={24}
                        isHalf={true}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#D51B21"
                        color="#D51B21"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}{" "}
          </div>
        )}
      </div>

      <form>
        <div className="ml-[700px] mt-12 text-sm font-normal ">
          <p>
            Your email address will not be published. Required fields are marked{" "}
            <span className="text-red-700">*</span>
          </p>
          <p className="mt-6">
            Your Rating <span className="text-red-700 ">*</span>
          </p>
          <p>
            <ReactStars
              id="rating"
              name="rating"
              onChange={ratingChanged}
              required
              count={5}
              size={24}
              isHalf={true}
              emptyIcon={<i className="far fa-star"></i>}
              halfIcon={<i className="fa fa-star-half-alt"></i>}
              fullIcon={<i className="fa fa-star"></i>}
              activeColor="#ffd700"
              // color="#ffd700"
            />
          </p>
          <p className="mt-6">
            Your Review <span className="text-red-700 ">*</span>
          </p>
          <textarea
            id="reviews"
            name="reviews"
            onChange={(e) => setValues({ ...values, review: e.target.value })}
            required
            className="h-[100px] w-[400px]"
          ></textarea>

          <div className="">
            <div className="flex">
              <p className="mt-6">
                Name <span className="text-red-700 ">*</span>
              </p>
              <p className="ml-[150px] mt-6">
                Email <span className="text-red-700 ">*</span>
              </p>
            </div>
            <div className="flex gap-4">
              <input
                id="names"
                name="names"
                onChange={(e) =>
                  setValues({ ...values, reviewer: e.target.value })
                }
                required
                type="text"
                placeholder="Enter Your Name"
                className="w-[180px]"
              />
              <input
                id="email"
                name="email"
                onChange={(e) =>
                  setValues({ ...values, reviewer_email: e.target.value })
                }
                required
                type="text"
                placeholder="Enter Your Email"
                className="w-[180px]"
              />
            </div>
          </div>

          <p className="mt-6">
            <input type="checkbox" />
            &nbsp;&nbsp;Save my name, email, and website in this browser for the
            next time I comment.
          </p>
          <button
            onClick={addReview}
            className="mt-6 bg-red-600 p-3 text-white"
          >
            Submit
          </button>
        </div>
      </form>

      {/* <h1 className="mt-8 text-center text-2xl font-bold	text-sky-900">
        RELATED PRODUCTS
      </h1> */}

      <div className="mt-6 flex">
        {product?.res?.map((item) => (
          <div className="ml-[400px] border p-2">
            <img
              className="w-[150px]"
              src="https://hips.hearstapps.com/vader-prod.s3.amazonaws.com/1700517566-716Agdq-YPL.jpg?crop=1xw:1.00xh;center,top&resize=980:*"
            />
            <p className="ml-4 text-sm">ABC Product</p>
            <p className="ml-4 mt-6 text-sm">
              KSh <span className="text-red-600">174.00</span>
            </p>
            <button className="ml-4 mt-6 rounded bg-red-600 p-1 text-sm text-white">
              SELECT COLOUR
            </button>
          </div>
        ))}
      </div>
    </Fragment>
  );
}
