import React, { useEffect, useState } from "react";
import { Carousel } from "flowbite-react";
import axios from "axios";

function Carousels() {
  const [product, setProduct] = useState([]);

  useEffect(() => {
    axios
      .get("https://checkndeal.skilldevelopers.in/api/banner_images.php")
      .then((res) => {
        setProduct(res.data);
      });
  }, []);

  // console.log("Carousel", product);
  return (
    <div className="h-[600px]">
      <div className="h-[600px]">
        <Carousel indicators={true}>
         
          <img src="https://shop.kenpoly.com/wp-content/uploads/2021/03/homepage-sliders-04.jpg" alt="..." />
        <img src="https://shop.kenpoly.com/wp-content/uploads/2022/06/homepage-sliders-3.jpg" alt="..." />
        <img src="https://shop.kenpoly.com/wp-content/uploads/2021/03/homepage-sliders-02.jpg" alt="..." />
        <img src="https://shop.kenpoly.com/wp-content/uploads/2021/03/homepage-sliders-01.jpg" alt="..." />
        </Carousel>
      </div>
    </div>
  );
}

export default Carousels;
