import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import image1 from "../../Images/image1.png";
import image2 from "../../Images/image2.png";
import image3 from "../../Images/image3.png";
import image4 from "../../Images/image4.png";
import image5 from "../../Images/image5.png";
import image6 from "../../Images/image6.png";
import image7 from "../../Images/image7.png";
import ReactStars from "react-rating-stars-component";
import { render } from "react-dom";

export default function OurRange() {
  const navigate = useNavigate()
  const [product, setProduct] = useState([]);
  const [products, setProducts] = useState([]);
  const [productVariation, setProductVariation] = [];
  const [cartItems, setCartItems] = useState("");
  const [productImage, setProductImage] = useState();
  const [selectedColorName, setSelectedColorName] = useState("IVORY"); // Initialize with default color name


  const [quantity, setQuantity] = useState(1); // Initialize quantity with 1

  // Function to handle quantity change
  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value);
    if (!isNaN(newQuantity) && newQuantity > 0) {
      setQuantity(newQuantity);
    }
  };

  // Add To Cart
  // Add To Cart
  const addToCartApi =
    "https://checkndeal.skilldevelopers.in/wp-json/wc/store/cart/items?consumer_key=ck_20d468c070adc2aef1512c9731275b475c51d7da&consumer_secret=cs_a440fcd3b8fabc5525f83f92a0a984f3dee641b1&id=5112&quantity=2";
  const addToCarts = () => {
    const existingCartItems = JSON.parse(localStorage.getItem("cart")) || [];

    const newCartItem = {
      id: product.id,
      name: product.name,
      price: product.price,
      quantity: quantity, // Use the quantity state variable here
      color: selectedColorName, // Save selected color name
      // image: productImage.image.src, // Save product image
      // variation_id: selectedColorName,
    };
    // console.log("Variation_id", variation_id);

    const updatedCartItems = [...existingCartItems, newCartItem];

    localStorage.setItem("cart", JSON.stringify(updatedCartItems));

    navigate("/cart");
  };


  const handleDecrement = (index) => {
    const updatedCartItems = [...cartItems];
    if (updatedCartItems[index].quantity > 1) {
      updatedCartItems[index].quantity--;
      setCartItems(updatedCartItems);
    }
  };

  const handleIncrement = (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems[index].quantity++;
    setCartItems(updatedCartItems);
  };

  useEffect(() => {
    axios
      .get(
        "https://checkndeal.skilldevelopers.in/wp-json/wc/v3/products?featured=true&consumer_key=ck_5cb86662b3f148136f41dede89999264419cba87&consumer_secret=cs_52258b1d43f07c649c19698bd77974e99648772b&per_page=45"
      )
      .then((res) => {
        setProduct(res.data);
      });
    console.log("rrrrrrrrrrrrrrrrrrrrrrrrrr", product);

    axios
      .get(
        "https://checkndeal.skilldevelopers.in/wp-json/wc/v3/products/categories?consumer_key=ck_5cb86662b3f148136f41dede89999264419cba87&consumer_secret=cs_52258b1d43f07c649c19698bd77974e99648772b"
      )
      .then((res) => {
        setProducts(res.data);
      });

    // Product Variation
  }, []);

  const options = {
    edit: false,
    color: "rgba(20,20,20,0.1)",
    activeColor: "tomato",
    size: window.innerWidth < 600 ? 20 : 25,
    value: product.average_rating,
    isHalf: true,
  };

  const ratingChanged = (newRating) => {
    console.log(newRating);
  };

  console.log("variations are", product);



  const [isToggled, setIsToggled] = useState(false);

  const toggleButton = () => {
    setIsToggled(!isToggled);
  };

  return (
    <div>
      <div className="mt-4 mr-4">
        <h1 className="ml-4 text-[#212529]  text-xl mr-4">Our Range</h1>
        <div class="grid grid-cols-7 gap-2 ml-4 mt-4">
          <Link to="/product-category/crates/">
            <div className="border p-2">
              <div>
                <img className="p-2" src={image1} />
              </div>
              <p className="text-[#000] text-center mt-2">CRATES</p>
            </div>
          </Link>

          <Link to="/product-category/furniture/">
            <div className="border p-2">
              <div>
                <img className="p-2" src={image2} />
              </div>
              <p className="text-[#000] text-center mt-2">FURNITURE</p>
            </div>
          </Link>

          <Link to="/product-category/household/">
            <div className="border p-2">
              <div>
                <img className="p-2" src={image3} />
              </div>
              <p className="text-[#000] text-center mt-2">HOUSEHOLD</p>
            </div>
          </Link>

          <Link to="/product-category/industrial/">
            <div className="border p-2">
              <div>
                <img className="p-2" src={image4} />
              </div>
              <p className="text-[#000] text-center mt-2">INDUSTRIAL</p>
            </div>
          </Link>

          <Link to="/product-category/kids/">
            <div className="border p-2">
              <div>
                <img className="p-2" src={image5} />
              </div>
              <p className="text-[#000] text-center mt-2">KIDS</p>
            </div>
          </Link>

          <Link to="/product-category/planter/">
            <div className="border p-2">
              <div>
                <img className="p-2" src={image6} />
              </div>
              <p className="text-[#000] text-center mt-2">PLANTER</p>
            </div>
          </Link>

          <Link to="/product-category/poultry/">
            <div className="border p-2">
              <div>
                <img className="p-2" src={image7} />
              </div>
              <p className="text-[#000] text-center mt-2">POULTRY</p>
            </div>
          </Link>
        </div>
      </div>

      {/* Best Seller Start */}

      <div className="mt-4 ml-4 mr-4 ">
        <h1 className="ml-4 text-[#212529]  text-xl	">Best Seller</h1>
        <div class="grid grid-cols-7 gap-2 ml-4 mt-4 ">
          {product.map((ob, index) => (
            // <div></div>
            <div className="border p-2 col-span-1">
              <Link to={"/product/" + ob.id}>
                <div>
                  <img className="p-2" src={ob.images[0].src} alt="" />
                </div>
              </Link>
              <p className="text-[#000] text-center text-[10px]	 mt-2">
                {ob.name}
              </p>

              <ReactStars
                count={ob.average_rating}
                // onChange={ob.average_rating}/
                size={24}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fas fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#D51B21"
                isHalf={true}
                color="#D51B21"
              />
              <p className="text-[#000] text-center text-xs mt-6">
                KSh <span className="text-red-500">{ob.price}</span>{" "}
              </p>
              {/* </Link> */}

              {ob.type == "variable" ? (
                <Link to={"/product/" + ob.id}>
                <button className="bg-red-700 p-2 text-[10px] text-white mt-2 h-[30px] ml-5">
                  SELECT COLOUR
                </button>
                </Link>
              ) : (
                <div>
                  <button
                    className="bg-red-700 p-2 text-[10px] text-white mt-2 h-[30px] ml-5"
                    onClick={toggleButton}
                  >
                    {isToggled ? "" : "Add To Cart"}
                  </button>
                  {isToggled && (
                    <div>
                      {" "}
                      <div className="flex gap-4 "  onClick={addToCarts}>
                        <button>-</button>
                        <input
                type="number"
                className="w-[50px] p-2 h-[40px]"
                value={quantity}
                onChange={handleQuantityChange}
              />
                        <button>+</button>
                      </div>{" "}
                    </div>
                  )}
                </div>
              )}

              {/* Quantity div  */}
              {/* <div className="flex gap-4">
            <button>-</button>
            <div>15</div>
            <button>+</button>
          </div> */}
              {/* Quantity div  */}
            </div>
          ))}
        </div>
      </div>

      {/* Best Seller End */}
    </div>
  );
}
