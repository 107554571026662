import React, { Fragment } from "react";

export default function ShippingPolicy() {
  return (
    <Fragment>
      <div className="ml-[150px] mt-6">
        <ul style={{ "list-style-type": "circle" }}>
          <li className="mt-2 mb-2">
            Shipping: Kenpoly endeavors to give the best online shopping
            experience at every step. Shipment is free for orders above
            Ksh5,000, orders below incur a standard delivery fee of Ksh500 for
            shipment countrywide**
          </li>
          <li className="mt-2 mb-2">
            Orders can also be picked-up free of charge from our Nairobi Branch
            on Lunga Lunga Road.
          </li>
          <li className="mt-2 mb-2">
            Please note this excludes certain hard to access areas such as
            Garissa, Isiolo, Lodwar, Lokichogio, Lamu & Taveta which will incur
            additional shipping costs as provided by the courier company.
          </li>
          <li className="mt-2 mb-2">
            Shipping address: Please ensure your shipping address is accurate to
            ensure a smooth and swift delivery.
          </li>
          <li className="mt-2 mb-2">
            Delivery Time: We will undertake to ship and deliver your order next
            day in Nairobi, and within 3 working days outside. Please note
            shipping times are estimates and not guaranteed.
          </li>
        </ul>

        <div className="mt-6 underline-offset-2	">
          <p className="underline underline-offset-2">
            Operating Hours for Pick-Up at our Lunga Lunga Rd Branch:
          </p>
          <ul style={{ "list-style-type": "circle" }}>
            <li className="mt-2 mb-2">Monday to Friday: 8:30 am – 4:00 pm.</li>
            <li className="mt-2 mb-2">Saturday: 8:30 am – 12:30 pm.</li>
            <li className="mt-2 mb-2">Sundays and public holidays – Closed.</li>
            <li className="mt-2 mb-2">
              Payment: Full payment must be made in advance either via mobile
              money (M-pesa / Airtel money) or Credit Card.
            </li>
          </ul>
        </div>

        <div className="mt-6">
          <p className="underline underline-offset-2">
            {" "}
            Operating Hours for Pick-Up at our Mombasa Branch:
          </p>
          <ul style={{ "list-style-type": "circle" }}>
            <li className="mt-2 mb-2">Monday to Friday: 8:30 am – 4:00 pm.</li>
            <li className="mt-2 mb-2">Saturday: 8:30 am – 12:30 pm.</li>
            <li className="mt-2 mb-2">Sundays and public holidays – Closed.</li>
            <li tyle={{ "list-style-type": "circle" }} className="mt-2 mb-2">
              Payment: Full payment must be made in advance either via mobile
              money (M-pesa / Airtel money) or Credit Card.
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
}
