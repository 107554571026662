import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiFillDashboard } from "react-icons/ai";
import { BiSolidCartDownload } from "react-icons/bi";
import { IoIosHome } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import { IoMdLogOut } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import Swal from "sweetalert2";
import axios from "axios";
import { format } from 'date-fns';
import { IoIosEye } from "react-icons/io";


export default function Order() {
  const navigate = useNavigate();

  const [orderDetails, setOrderDetails] = useState(null);
  const [subtotal, setSubtotal] = useState(0);
  const [billingAddress, setBillingAddress] = useState(null);
  const [orderid, setOrderid] = useState(null);
  const [order, setOrder] = useState(null);
  // const [orderDetails, setOrderDetails] = useState('')
  const params = useParams();

  useEffect(() => {

         // Get User Data from Local Storage
  const userData = localStorage.getItem("userData");
  
  const parsedUserData = JSON.parse(userData);
  const id = parsedUserData.ID
  console.log("User Data:", id);

    axios
      .get(
        `https://checkndeal.skilldevelopers.in/wp-json/wc/v3/orders?consumer_key=ck_5cb86662b3f148136f41dede89999264419cba87&consumer_secret=cs_52258b1d43f07c649c19698bd77974e99648772b&customer=${id}`
      )
      .then((res) => {
        setOrder(res.data);
      });
   

    // Retrieve order details from local storage
    const storedOrderDetails = JSON.parse(localStorage.getItem("orderDetails"));
    setOrderDetails(storedOrderDetails);

    // Retrieve checkout form data from local storage
    const storedCheckoutFormData = JSON.parse(
      localStorage.getItem("checkoutFormData")
    );
    setBillingAddress(storedCheckoutFormData);
    // console.log("iddddddddddddddddddddd", storedCheckoutFormData);

    // Retrieve orderId details from local storage
    const orderIdNumber = JSON.parse(localStorage.getItem("orderId"));
    setOrderid(orderIdNumber);

    // Calculate subtotal
    if (storedOrderDetails) {
      const subTotalAmount = storedOrderDetails.cartItems.reduce(
        (total, item) => total + item.price * item.quantity,
        0
      );
      setSubtotal(subTotalAmount);
    }
  }, []);

  const handleLogout = () => {
    Swal.fire({
      icon: "info",
      title: "Logging Out",
      text: "Are you sure you want to log out?",
      showCancelButton: true,
      confirmButtonText: "Yes, Log Out",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // Clear userData from localStorage
        localStorage.removeItem("userData");
        // Redirect to the login page
        navigate("/login");
        window.location.reload();
        // Show logout success alert
        Swal.fire({
          icon: "success",
          title: "Logged Out",
          text: "You have been logged out successfully.",
        });
      }
    });
  };
  console.log("Order By ID", order);
  return (
    <Fragment>
      <div className="ml-[150px]">
        <div className="flex w-[80vw] gap-4">
          <div className="w-[60vw]  p-4">
            <div></div>
            <table className="w-[700px] -ml-[55px] text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ml-[50px] mr-[100px]">
              <thead className="text-xs text-white uppercase bg-red-700 dark:bg-gray-700 dark:text-gray-400 ">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Order
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Total
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Tracking
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Actions
                  </th>
                  {/* <th scope="col" className="px-6 py-3">
                    Action
                  </th> */}
                </tr>
              </thead>

              <tbody className="mt-4 p-2 mt-2 mb-2">
                {order &&
                  order.map((item) => (
                    <tr className="p-4 mt-2 mb-2">
                      {/* to={"/product/" + ob.id} */}
                      <td className="mt-4 text-red-600"><Link to={'/order/'+item.id}>#{item.number}</Link></td>
                      <td>{format(new Date(item.date_created), 'dd MMMM yyyy')}</td>
                      {/* <td>{item.date_created}</td> */}
                      
                      <td>{item.status}</td>
                      <td>{item.shipping_total}</td>
                      <td></td>
                      <td>
                        <Link to={'/order/'+item.id} >
                  <button className="item-center bg-red-600 text-white p-2 flex gap-2">View<IoIosEye className="mt-1" /></button>
                  </Link>
                </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="w-[20vw] border text-red-500">
            <hr />
            <Link to="/dashboard">
              {" "}
              <div className="flex ml-4 mt-6 p-2">
                Dashboard <AiFillDashboard className="ml-[90px] w-[50px]" />
              </div>
            </Link>
            <hr />
            <Link to="/orders">
              {" "}
              <div className="flex ml-4 mt-6 p-2">
                Orders <BiSolidCartDownload className="ml-[120px] w-[50px]" />
              </div>
            </Link>
            <hr />
            <Link to="/address">
              {" "}
              <div className="flex ml-4 mt-6 p-2">
                Addresses <IoIosHome className="ml-[100px] w-[50px]" />
              </div>
            </Link>
            <hr />
            <Link to="/account">
              {" "}
              <div className="flex ml-4 mt-6 p-2">
                Account Details <CgProfile className="ml-[60px] w-[50px]" />
              </div>
            </Link>
            <hr />
            <div className="flex ml-4 mt-6 p-2" onClick={handleLogout}>
              <Link>Log Out</Link>{" "}
              <IoMdLogOut className="ml-[120px] w-[50px]" />
            </div>
            <hr />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
