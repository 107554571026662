import axios from "axios";
import React, { useEffect, useState } from "react";
import mastercard from "../../Images/mastercard.png";
import facebook from "../../Images/facebook.png";
import instagram from "../../Images/instagram.png";
import youtube from "../../Images/youtube.png";
import { Link } from "react-router-dom";
import footer from "../../Images/footer.png";

export default function Footer() {
  return (
    <div className="bg-slate-200">
      <div className=" p-4 mt-10 border-md h-[300px] flex text-[16px] text-slate-700	">
        <div className="mt-8 ml-6 font-light	">
          <a href="/exchange-return-policy">Exchange/Return Policy</a>
          <br />
          <a href="/consent-notice">Consent Notice</a>
        </div>

        <div className="mt-10 ml-[150px] font-light">
          <a href="/purchases-payment-and-delivery/">Shipping Policy</a>
        </div>

        <div className="mt-10 ml-[150px] font-light">
          <img src={mastercard} />
        </div>

        <div className="mt-10 ml-[50px] font-light flex gap-2">
          <Link to="https://www.facebook.com/" target="_blank">
            {" "}
            <img
              className="w-[30px] h-[30px] bg-violet-500 hover:bg-violet-600"
              src={facebook}
            />
          </Link>
          <Link to="https://www.instagram.com/" target="_blank">
            {" "}
            <img className="w-[30px] h-[30px]" src={instagram} />
          </Link>
          <Link to="https://www.youtube.com/" target="_blank">
            <img className="w-[30px] h-[30px]" src={youtube} />
          </Link>
        </div>
        <hr />

        <div></div>
      </div>
      <div className="flex">
        <div>
          <p className="ml-10 -mt-[50px] text-slate-400 text-xs">
            Copyright © 2020-Compulynx, Inc. All rights reserved.
          </p>
        </div>
        <div className="flex ml-[500px]">
          <p className="ml-10 -mt-[50px] text-slate-400 text-xs flex mt-4">
            Powered By:{" "}
            <Link to="/">
              <img
                className="w-[130px] h-[50px] flex -mt-[15px]"
                src={footer}
              />
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
