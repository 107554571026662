import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiFillDashboard } from "react-icons/ai";
import { BiSolidCartDownload } from "react-icons/bi";
import { IoIosHome } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import { IoMdLogOut } from "react-icons/io";
import axios from "axios";

export default function UpdateAddress() {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address_1, setAddress_1] = useState("");
  const [address_2, setAddress_2] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  // Update Method
  const handleSaveAddress = () => {
    const userData = localStorage.getItem("userData");
    const parsedUserData = JSON.parse(userData);
    const id = parsedUserData.ID;

    const updatedAddress = [
      {
        first_name: firstName,
        last_name: lastName,
        billing: {
          address_1: address_1,
          address_2: address_2,
          city: city,
          postcode: postalCode,
          phone: phone,
        },
        email: email,
      },
    ];

    axios
      .put(
        `https://checkndeal.skilldevelopers.in/wp-json/wc/v3/customers/${id}/?consumer_key=ck_5cb86662b3f148136f41dede89999264419cba87&consumer_secret=cs_52258b1d43f07c649c19698bd77974e99648772b`,
        updatedAddress
      )
      .then((res) => {
        // Handle success
        console.log("Address updated successfully:", res.data);
        navigate("/address"); // Navigate to the address page or any other page
      })
      .catch((error) => {
        console.error("Error updating address:", error);
      });
  };

  // Get Address Method From Order API
  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const parsedUserData = JSON.parse(userData);
    const id = parsedUserData.ID;

    axios
      .get(
        `https://checkndeal.skilldevelopers.in/wp-json/wc/v3/customers/${id}/?consumer_key=ck_5cb86662b3f148136f41dede89999264419cba87&consumer_secret=cs_52258b1d43f07c649c19698bd77974e99648772b`
      )
      .then((res) => {
        const addressData = res.data;
        setFirstName(addressData.first_name || "");
        setLastName(addressData.last_name || "");
        setAddress_1(addressData.billing.address_1 || "");
        setAddress_1(addressData.billing.address_2 || "");
        setCity(addressData.billing.city || "");
        setPostalCode(addressData.billing.postcode || "");
        setPhone(addressData.billing.phone || "");
        setEmail(addressData.email || "");
      })
      .catch((error) => {
        console.error("Error fetching address:", error);
      });
  }, []);
  console.log("Streetadresssssssssss", email);
  return (
    <Fragment>
      <div className="ml-[150px]">
        <div className="flex w-[80vw] gap-4">
          <div className="w-[60vw] mt-[80px]  p-4">
            <label>First Name (Optional)</label>
            <br />
            <div className="flex ">
              <input
                className="bg-slate-200"
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>

            <div className="ml-[300px] -mt-[66px]">
              <label>Last Name (Optional)</label>
              <br />
              <div className="flex ">
                <input
                  className="bg-slate-200"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                  placeholder="Last Name"
                />
              </div>
            </div>

            <div className="w-[800px] mt-12">
              <label>
                Street address <span className="text-red-500">*</span>
              </label>
              <br />
              <div className="flex ">
                <input
                  value={address_1}
                  onChange={(e) => setAddress_1(e.target.value)}
                  className="bg-slate-200 w-[700px]"
                  type="text"
                  placeholder="Home number and street name"
                />
              </div>
            </div>

            <div className="w-[800px] mt-12">
              <label>
                Town / City <span className="text-red-500">*</span>
              </label>
              <br />
              <div className="flex ">
                <input
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  className="bg-slate-200 w-[700px]"
                  type="text"
                  placeholder="Town/City"
                />
              </div>
            </div>

            {/* <div className="w-[800px] mt-12">
              <label>
                State / Country <span className="text-red-500">*</span>
              </label>
              <br />
              <select className=" w-[700px]">
                  {countriesArr.map((country) => {
                    return <option>{country?.country}</option>;
                  })}
                </select>
            </div> */}

            <div className="w-[800px] mt-12">
              <label>
                Postal Code <span className="text-red-500">*</span>
              </label>
              <br />
              <div className="flex ">
                <input
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                  className="bg-slate-200 w-[700px]"
                  type="text"
                  placeholder=""
                />
              </div>
            </div>

            <div className="w-[800px] mt-12">
              <label>Phone (optional)</label>
              <br />
              <div className="flex ">
                <input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="bg-slate-200 w-[700px]"
                  type="text"
                />
              </div>
            </div>

            <div className="w-[800px] mt-12">
              <label>Email address (optional)</label>
              <br />
              <div className="flex ">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="bg-slate-200 w-[700px]"
                  type="email"
                />
              </div>
            </div>

            <div className="mt-6">
              <button
                onClick={handleSaveAddress}
                className="bg-red-600 p-2 rounded text-white"
              >
                SAVE ADDRESS
              </button>
            </div>
          </div>
          <div className="w-[20vw] border text-red-500 h-[400px]">
            <hr />
            <Link to="/dashboard">
              {" "}
              <div className="flex ml-4 mt-6 p-2">
                Dashboard <AiFillDashboard className="ml-[90px] w-[50px]" />
              </div>
            </Link>
            <hr />
            <Link to="/orders">
              {" "}
              <div className="flex ml-4 mt-6 p-2">
                Orders <BiSolidCartDownload className="ml-[120px] w-[50px]" />
              </div>
            </Link>
            <hr />
            <Link to="/address">
              {" "}
              <div className="flex ml-4 mt-6 p-2">
                Addresses <IoIosHome className="ml-[100px] w-[50px]" />
              </div>
            </Link>
            <hr />
            <Link to="/account">
              {" "}
              <div className="flex ml-4 mt-6 p-2">
                Account Details <CgProfile className="ml-[60px] w-[50px]" />
              </div>
            </Link>
            <hr />
            <Link to="/logout">
              {" "}
              <div className="flex ml-2 mt-6 p-2">
                Log Out <IoMdLogOut className="ml-[120px] w-[50px]" />
              </div>
            </Link>
            <hr />{" "}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
