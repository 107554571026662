import React, { Fragment } from "react";

export default function TermsAndCondition() {
  return (
    <Fragment >
    <div className="ml-[150px] mt-10">
    <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras lectus
        nunc, malesuada ut scelerisque nec, sollicitudin nec ipsum. Pellentesque
        quis dignissim mi, a imperdiet libero. Sed malesuada ante eu porta
        pulvinar. Morbi laoreet elementum lectus. Ut vehicula nunc et tincidunt
        venenatis. Ut hendrerit iaculis ipsum, eu consectetur quam rhoncus nec.
        Vestibulum facilisis sem sit amet odio luctus, vel venenatis mi
        hendrerit. Fusce at quam blandit, facilisis mi id, sagittis augue.
        Aenean eu vulputate leo. Etiam dapibus nisi nec ligula cursus aliquam.
        Donec at dictum nunc. Morbi efficitur tempus nisi, accumsan consectetur
        felis. Interdum et malesuada fames ac ante ipsum primis in faucibus.
        Mauris posuere et dolor eget auctor. Morbi porttitor mi in ex hendrerit,
        ac accumsan est viverra.
      </p>
      <p className="mt-10">
        Nulla lorem dolor, venenatis id consequat quis, semper in diam. Vivamus
        feugiat lacus a vulputate ultrices. Mauris metus mauris, iaculis vel
        dolor vel, sagittis hendrerit orci. Pellentesque nunc sapien, mattis at
        orci a, tincidunt tincidunt mauris. Donec in scelerisque neque.
        Suspendisse gravida at urna at aliquam. Cras vestibulum ex erat, a
        rhoncus quam pellentesque ut. Aenean quis volutpat ante. Praesent
        volutpat tincidunt metus, mollis laoreet augue pulvinar ut. Aenean sed
        auctor arcu. Mauris efficitur leo eget elementum ornare. Mauris non
        laoreet risus. Nam sed pharetra enim. Aenean vitae placerat libero.
      </p>
      <p className="mt-10">
        Maecenas varius porta efficitur. Integer sed arcu non nunc rutrum
        vehicula nec sit amet enim. Duis ante enim, pellentesque nec sapien vel,
        dictum scelerisque tellus. Nullam sit amet massa id sapien egestas
        ultricies. In ut erat eget nibh hendrerit pellentesque sed sit amet
        eros. Integer sed enim a est tincidunt ultricies. Sed rutrum arcu ut sem
        vehicula, sit amet egestas sem ultrices. Vivamus vel egestas massa.
        Quisque pharetra efficitur dapibus. Donec et sem at turpis iaculis
        feugiat ac in diam. Sed rhoncus eget augue et vulputate. Etiam pretium
        auctor lacinia.
      </p>
      <p className="mt-10"> 
        Aliquam gravida non felis nec dignissim. Interdum et malesuada fames ac
        ante ipsum primis in faucibus. In ultrices sollicitudin felis, et
        rhoncus quam vehicula ac. Quisque ultrices vitae velit sit amet blandit.
        Integer tempus aliquam pellentesque. Duis convallis vitae tellus vitae
        tincidunt. Cras semper pellentesque arcu, sit amet posuere ex vehicula
        quis.
      </p>
      <p className="mt-10">
        Phasellus egestas nunc vitae arcu rutrum ultricies. Aliquam auctor
        blandit metus id porta. Morbi semper id odio at interdum. Nullam pretium
        lacinia lectus sit amet semper. Nulla maximus ut lacus a tincidunt.
        Quisque sit amet semper turpis. Fusce finibus congue ipsum convallis
        sagittis. Fusce porta commodo consequat.
      </p>
      <p className="mt-10 mb-12" >
        Nulla accumsan est at nibh mattis, posuere finibus nibh porta. Donec
        dapibus risus in velit iaculis, ut vehicula ligula ultricies. In eu
        mattis nisi. Etiam eros lectus, placerat quis venenatis a, tristique
        eget eros. Suspendisse sed erat justo. Ut ante dolor, consectetur sit
        amet tristique nec, placerat sed risus. Praesent quis ultrices nunc.
        Aliquam tempus neque ac velit dictum laoreet. Vestibulum suscipit, leo
        quis gravida venenatis, diam nibh sodales odio, vitae tincidunt lectus
        elit vel urna. Suspendisse blandit, massa non convallis volutpat, enim
        turpis sollicitudin ipsum, nec aliquam sem lorem vel enim. Nullam
        dapibus gravida nunc ut gravida.
      </p>
    </div>
    </Fragment>
  );
}
