import React, { Fragment, useEffect, useState } from "react";
import { IoEyeSharp } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { TiEye, TiEyeOutline } from "react-icons/ti";
import Swal from "sweetalert2";

export default function SignUp() {
  const navigate = useNavigate();
  const [showPasswords, setShowPasswords] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const togglePasswordVisibility = () => {
    setShowPasswords(!showPasswords);
  };

  useEffect(() => {
    // Retrieve email and password from localStorage if "Remember me" was previously checked
    const rememberedEmail = localStorage.getItem("rememberedEmail");
    const rememberedPassword = localStorage.getItem("rememberedPassword");

    if (rememberedEmail && rememberedPassword) {
      setEmail(rememberedEmail);
      setPassword(rememberedPassword);
      setRememberMe(true);
    }
  }, []);

  // Register Method
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    username: "",
  });

  const registerUser = (e) => {
    e.preventDefault();

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!passwordRegex.test(values.password)) {
      Swal.fire({
        icon: "error",
        title: "Invalid Password",
        text: "Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one digit, and one special character.",
      });
      return;
    }

    axios
      .post(
        "https://checkndeal.skilldevelopers.in/wp-json/wc/v3/customers?consumer_key=ck_5cb86662b3f148136f41dede89999264419cba87&consumer_secret=cs_52258b1d43f07c649c19698bd77974e99648772b",
        values
      )
      .then((res) => {
        setValues(res.values);
        navigate("/login");
        Swal.fire({
          icon: "success",
          title: "Registration Successful",
          text: "You have successfully registered!",
        });
      })
      .catch((error) => {
        console.error("Registration failed: ", error);
      });
  };

  // Login Method
  const handleLogin = (e) => {
    e.preventDefault();
    axios
      .post(
        "https://checkndeal.skilldevelopers.in/wp-json/custom-plugin/login",
        values
      )
      .then((res) => {
        setValues(res.data);
        localStorage.setItem("userData", JSON.stringify(res.data));
        navigate("/cart/:id");
        window.location.reload();
        Swal.fire({
          icon: "success",
          title: "Login Successful",
          text: "You have successfully logged in!",
        });
      })
      .catch((error) => {
        console.error("Login failed: ", error);
        Swal.fire({
          icon: "error",
          title: "Login Failed",
          text: "Invalid username or password. Please try again.",
        });
      });

  };

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  // Forgot Password Method
  const forgotPassword = () => {
    // Handle forgot password action here
    // For example, you can navigate to a separate "Forgot Password" page
    navigate("/my-account/lost-password/");
  };

  // Show Password Method

  const showPassword = () => {
    var pass = document.getElementById("password");
    if (pass.type === "password") {
      pass.type = "text";
    } else {
      pass.type = "password";
    }
  };

  const saveCredentialsToLocalStorage = () => {
    if (rememberMe) {
      localStorage.setItem("rememberedEmail", email);
      localStorage.setItem("rememberedPassword", password);
    } else {
      localStorage.removeItem("rememberedEmail");
      localStorage.removeItem("rememberedPassword");
    }
  };

  return (
    <Fragment>
      <div className="flex w-[100vw]">
        {/* Login Div Start */}
        <form>
          <div>
            <p className="ml-[100px] mt-6 bg-slate-200 rounded	w-[60px] p-2">
              Login
            </p>
            <div className="w-[45vw] border mt-12 ml-8">
              <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                  <form className="space-y-6">
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Username or email address{" "}
                        <span className="text-red-700">*</span>
                      </label>
                      <div className="mt-2">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          required
                          onChange={(e) =>
                            setValues({ ...values, username: e.target.value })
                          }
                          autoComplete="email"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="flex items-center justify-between">
                        <label
                          htmlFor="password"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Password <span className="text-red-700">*</span>
                        </label>
                      </div>

                      <div className="mt-2 flex">
                        <input
                          id="password"
                          name="password"
                          type={showPasswords ? "text" : "password"}
                          // value={password}
                          onChange={(e) =>
                            setValues({ ...values, password: e.target.value })
                          }
                          // type="password"
                          autoComplete="current-password"
                          required
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {showPasswords ? (
                          <TiEyeOutline
                            onClick={togglePasswordVisibility}
                            className="w-12 -ml-16 mt-2"
                          />
                        ) : (
                          <TiEye
                            onClick={togglePasswordVisibility}
                            className=" mt-2 w-12 -ml-16 inset-y-0 right-0 pr-3 flex items-center cursor-pointer text-gray-500 hover:text-gray-700"
                          />
                        )}
                      </div>

                      <div className="flex mt-4">
                        <input
                          type="checkbox"
                          checked={rememberMe}
                          onChange={handleRememberMe}
                        />
                        <p className="ml-4 text-gray-900 text-sm">
                          {" "}
                          Remember me
                        </p>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="text-sm text-red-600">
                        <button onClick={forgotPassword}>
                          {" "}
                          Forgot password?
                        </button>
                      </div>
                      <div>
                        <button
                          type="submit"
                          onClick={handleLogin}
                          // onClick={handleLogin}
                          className="flex w-[100px] ml-[140px] justify-center rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          LOG IN
                        </button>
                      </div>
                    </div>
                  </form>

                  <p className="mt-10 text-center text-md text-red-700 font-bold	">
                    CONNECT WITH
                  </p>

                  <div className="flex  mt-8 gap-4">
                    <Link to="https://www.facebook.com/">
                      <button
                        type="button"
                        class="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 focus:ring-4 focus:outline-none focus:ring-[#3b5998]/50 font-medium rounded-lg text-sm px-2 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 me-2 mb-2"
                      >
                        <svg
                          class="w-4 h-4 me-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 8 19"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        Login with Facebook
                      </button>
                    </Link>

                    <Link to="https://mail.google.com">
                      <button
                        type="button"
                        class="text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-2 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 me-2 mb-2"
                      >
                        <svg
                          class="w-4 h-4 me-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 18 19"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        Login with Google
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        {/* Login Div End */}
        <div>
          <p className=" ml-12 text-2xl font-extralight	 mt-[40px]">
            Create an account
          </p>
          <div className="w-[45vw] border mt-12 ml-8">
            <div className="flex min-h-full flex-1 flex-col justify-center px-2 py-6 lg:px-8">
              <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="flex mt-4 items-center justify-between">
                  <label
                    htmlFor="number"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    User Name<span className="text-red-700">*</span>
                  </label>
                </div>

                <div className="mt-2">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    onChange={(e) =>
                      setValues({ ...values, name: e.target.value })
                    }
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>

                <div>
                  <div className="flex mt-4 items-center justify-between">
                    <label
                      htmlFor="number"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email address<span className="text-red-700">*</span>
                    </label>
                  </div>

                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      // value={email}
                      onChange={(e) =>
                        setValues({ ...values, email: e.target.value })
                      }
                      autoComplete="email"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  <div className="flex mt-4 items-center justify-between">
                    <label
                      htmlFor="number"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Password<span className="text-red-700">*</span>
                    </label>
                    <input
                      id="password"
                      name="password"
                      type={showPasswords ? "text" : "password"}
                      onChange={(e) =>
                        setValues({ ...values, password: e.target.value })
                      }
                      autoComplete="current-password"
                      required
                      className="rounded-md border-0 py-1.5 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {/* Toggle password visibility button/icon */}
                    {showPasswords ? (
                      <TiEyeOutline
                        onClick={togglePasswordVisibility}
                        className="w-12 -ml-16"
                      />
                    ) : (
                      <TiEye
                        onClick={togglePasswordVisibility}
                        className=" w-12 -ml-16 inset-y-0 right-0 pr-3 flex items-center cursor-pointer text-gray-500 hover:text-gray-700"
                      />
                    )}
                  </div>
                </div>

                <p className="text-sm mt-6">
                  Your personal data will be used to support your experience
                  throughout this website, to manage access to your account, and
                  for other purposes described in our{" "}
                  <Link to="/privacy-policy" className="text-red-500">
                    privacy policy.
                  </Link>
                </p>

                <div className="ml-[180px] mt-8">
                  <button
                    // onClick={registerUser}
                    type="submit"
                    onClick={registerUser}
                    className="flex  w-[100px] ml-[140px] justify-center rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Register
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
