import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
// import { Provider } from 'react';
// import store from './Redux/Store';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  {/* <Provider storestore={store}> */}
    <App />
    {/* </Provider> */}
  </BrowserRouter>
);
reportWebVitals();
